import React from 'react';
import AppContext from '../../context/app'

import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import fileService from '../../services/file';
import { Button, Icon, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone'

class List extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { files: [], folders: [], assets: [], folder: this.props.match.params.folder }
    this.history = createBrowserHistory();
    this.fileService = new fileService();
    this.handleDownloadFile = this.handleDownloadFile.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
    this.toggleUploadFileSection = this.toggleUploadFileSection.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
  }

  async bindPage(props){    
    window.scroll(0,0)
    try {
      let result = await this.fileService.getAssets(props.match.params.folder)
      this.setState( { assets: result.assets, folders: result.folders, folder: props.match.params.folder } );
    }
    catch(err){
      console.log(err)
      this.context.toastError('Loading')
    }
  }

  componentWillReceiveProps(nextProps, nextContext){
    this.bindPage(nextProps)
  }

  componentWillMount () {
    this.bindPage(this.props)
  }

  async componentDidMount () {
    
  }

  handleGoBack(event) {
    let location = this.props.match.params.folder
    if (!location)
      window.location = 'https://login.clpsconsultants.com/home'
    else{
      let backFolder = ''
      if (location.indexOf('%2F') > -1)
        backFolder = location.slice(0, location.lastIndexOf('%2F'))
      window.location = `/file/${backFolder}`
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  toggleUploadFileSection(event){
    this.setState( { showUploadFile: !this.state.showUploadFile } );
  }

  handleChangeFiles(files){
    this.setState({files: files});
  }

  async handleUploadFile(event){
    event.preventDefault()

    try {
      async function uploadFiles() {
        for (const file of this.state.files) {
          let data = new FormData()
          data.append('file', file)
          await this.fileService.uploadFile(data, this.state.folder ? this.state.folder.replace('/','%2F') : '', this.state.notes)
        }
      }

      await uploadFiles.bind(this)()

      //reload the files
      let result = await this.fileService.getAssets(this.props.match.params.folder)
      this.setState( { assets: result.assets, folders: result.folders, showUploadFile: false, notes: null, folder: this.props.match.params.folder } );
      this.context.toastSuccess('File uploaded')
    }
    catch(err){
      console.log(err)
      this.context.toastError('File could not be uploaded')
    }

  }

  handleDownloadFile(e){
    let fileName = e.target.dataset.filename
    this.fileService.downloadAsset(fileName)
  }

  async handleDeleteImage(fileName){
    try {
      await this.fileService.deleteAsset(fileName)
      //reload the files
      let result = await this.fileService.getAssets(this.props.match.params.folder)
      this.setState( { assets: result.assets } );
      this.context.toastSuccess('File deleted')
    }
    catch(err){
      console.log(err)
      this.context.toastError('File could not be deleted')
    }

  }


  render () {
    return (
      <div id='assets'>
        <div className="back-folder">
            <i onClick={this.handleGoBack} className="fa fa-level-up">back</i>
        </div>

        <div>
            { !this.state.showUploadFile ?
              <div className='btn-next btn-upload'>
                  <span onClick={this.toggleUploadFileSection} >+ Upload File</span>
              </div>
              :  
              <form onSubmit={this.handleUploadFile}>
                <div className="dropzonearea">                  
                  <DropzoneArea
                    filesLimit={1}
                    maxFileSize={30000000}
                    onChange={this.handleChangeFiles.bind(this)}
                  />
                </div>
                <div>
                  <TextField className="input-field" name="folder" type="text" maxLength="50" value={this.state.folder ? this.state.folder.replace('%2F','/') : ''} label="Folder" onChange={this.handleChange} />
                </div>
                <div>
                  <Button type="submit" variant="outlined"><Icon className="fa fa-upload" />Upload File</Button>
                </div>
              </form> 
              }
        </div>

        <div>
            { this.state.folders.length ?
              <div className="folders flex-row">
              {
                this.state.folders.map( (folder, index) => {
                  let folderLink = `/file/${this.props.match.params.folder ? `${this.props.match.params.folder}%2F` : '' }${folder}`
                  return (
                      <div key={index} className="flex-col uploaded-folder-container">
                        <Link key={index} to={folderLink}>
                          <i className="fa fa-folder uploaded-folder"></i>
                          <div className="uploaded-folder-caption">
                            {folder}
                          </div>
                        </Link>
                      </div>
                  )
                })
              }
              </div>
            :
              null
            }
        </div>

            
        <div>
            { this.state.assets.length ?
              <div className="assets flex-row">
              {
                this.state.assets.map( (asset, index) => {
                  return (
                    <div className="flex-col uploaded-file-container" key={index}>
                      <span 
                        className="fa fa-file uploaded-file"
                        data-filename={asset.location}
                        alt={ asset.fileName  }
                        title={asset.fileName }
                        onClick={this.handleDownloadFile}
                      />

                      <span>
                          <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.handleDeleteImage(asset.location) }}></i>
                      </span>
                      <div className="uploaded-file-caption">
                        {asset.fileName}
                      </div>
                    </div>
                  )
                })
              }
              </div>
            :
              null
            }
        </div>
                
      </div>
    )
  }

 
}


export default List
