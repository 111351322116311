import React, { useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField, MenuItem } from '@mui/material';
import AddRemove from './AddRemove'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 6

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { safetyTeam: [{}], 
    commandRoles: [
      { role: 'Incident Commander' },
      { role: 'Public Information Officer' },
      { role: 'Safety Officer' },
      { role: 'Liaison Officer' },
      { role: 'Operations Chief' },
      { role: 'Planning Chief' },
      { role: 'Logistics Chief' }
    ], 
    commandPosts: [
      { post: 'Primary Inside CP' },
      { post: 'Secondary Inside CP' },
      { post: 'Primary Outside CP' },
      { post: 'Secondary Outside CP' }
    ], 
    staffSpecialSkills: [{}] }
  );


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData)
        setStudentReunificationData(JSON.parse(subModuleData.data))
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/reunification/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
            <h2>Guidelines for School Emergency Planning</h2>

            <div className="flex-row">
                <div className='flex-col full-width'>
                  <ul>
                    <li>Identify members of the School Safety Team
                      <ul>
                        <li>Ensure these team members members understand Command Structure</li>
                        <li>Update contact information as often as necessary</li>
                      </ul>
                    </li>
                    <li>Assign roles</li>
                    <li>Identify staff with special skills useful in emergency situations</li>
                    <li>Identify a primary and secondary location for an on-site Incident Command Post</li>
                    <li>Ensure Classroom Go-Kits and Emergency Folders are assembled/up to date</li>
                    <li>Plan for training, exercise and drill schedules for administration, staff, students, and volunteer per the drill schedule and log in Crisis Go</li>
                    <li>Review and revise school emergency communications plans</li>
                    <li>Identify Evacuation and Reunification processes
                      <ul>
                        <li>Designate on-site evacuation locations</li>
                        <li>Identify off-site emergency evacuation locations to include walking and transportation sites.</li>
                        <li>Plan evacuation routes</li>
                        <li>Develop plans with the district Transportation Dept. to coordinate buses for long distance off-site evacuation</li>
                        <li>Identify individuals needing evacuation assistance and develop Individualized Evacuation Plans</li>
                        <li>Develop plans for both on-site and off-site reunification processes</li>
                      </ul>
                    </li>
                    <li>Implement and maintain a system for documentation and sharing of information related to matters of a ‘public safety concern’ </li>
                    <li>Include building specific cheat sheets or phone trees (optional)</li>
                  </ul>
                </div>
            </div>

            <h2>School Safety Team &amp; Command</h2>
            <div className="flex-row">				
              The single most effective way of dealing with a critical incident or crisis situation is through the use of a school safety team. 						<br/>
              This team cannot be put together when the crisis or critical incident is unfolding but instead, each member must be in place and comfortable with his/her role long before a crisis or critical incident occurs. 						<br/>
              A team approach, which includes first responder partners, is required because there is no way a single person can manage the many aspects associated with a critical event.						<br/>
              Principals and site administrators must rely on other key school personnel to perform tasks that will ensure the safety of students, staff, and others during a crisis or critical incident.<br/>
              <br/>
              The following is a guideline for school administrators to use when developing their School Emergency Operations Plan (EOP).<br/>
              
            </div>

            <b>All elements listed below must be reviewed and updated annually.</b>
            
            <h3>Members of the School Safety Team</h3>
            <div className="flex-row">				
              The School Safety Team will be responsible for reviewing and updating the school emergency plan annually as well as planning for drills, exercises, and training. <br/>
              The safety team should include at a minimum; Principal, other administrators, office staff, plant manager, counselor, teacher, clinic assistant, student services, <br/>
              SRO/SSG and others with specialized skills.
            </div>

            <div>
              { studentReunificationData.safetyTeam.map( (safetyTeamMember, index) => {
                  return (
                    <div key={index} className="flex-row row small-cols top-connected">
                        <div className='flex-col'>
                          <TextField label="Staff Member" type="text" maxLength="45" value={safetyTeamMember.name || ''} required onChange={(e) => { safetyTeamMember.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Room" type="text" maxLength="45" value={safetyTeamMember.room || ''} required onChange={(e) => { safetyTeamMember.room = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Title" type="text" maxLength="45" value={safetyTeamMember.title || ''} required onChange={(e) => { safetyTeamMember.title = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Phone" type="text" maxLength="45" value={safetyTeamMember.phone || ''} required onChange={(e) => { safetyTeamMember.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Cell" type="text" maxLength="45" value={safetyTeamMember.cell || ''} required onChange={(e) => { safetyTeamMember.cell = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField select label="Radio" type="text" maxLength="45" value={safetyTeamMember.radio || ''} required onChange={(e) => { safetyTeamMember.radio = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="NA">NA</MenuItem>
                          </TextField>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.safetyTeam = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.safetyTeam}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>

            <h3>Command Roles</h3>
            <div className="flex-row">				
              Assigning Command roles allows you to efficiently and effectively handle all types of emergency situations, as well as planned events. <br/>
              Administrators and school staff must review Command roles and functions annually.  This should be done in conjunction with the annual review of the school plan.<br/>
              Emphasis should be placed on the interchangeability of roles under this structure and the importance of student supervision in the event of an emergency.
            </div>

            <div>
              { studentReunificationData.commandRoles.map( (commandRole, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                      <div className='flex-col'>
                        <span className="row-title">{ commandRole.role }</span>
                      </div>
                      <div className='flex-col'>
                        <TextField label="Primary" type="text" maxLength="100" value={commandRole.name || ''} required onChange={(e) => { commandRole.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <TextField label="1st Backup" type="text" maxLength="100" value={commandRole.backupName || ''} required onChange={(e) => { commandRole.backupName = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <TextField label="2nd Backup" type="text" maxLength="100" value={commandRole.backup2Name || ''} required onChange={(e) => { commandRole.backup2Name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                    </div>
                  )
                })}
            </div>

            <div className="flex-row">				
              Incident Commander- Normally would be the Principal if it is a Campus event that you will remain on site. Oversee's the whole operation.<br/>
              Public Information Officer- Communicates with the community and coordinates with district PIO.<br/>
              Safety Officer-  SSG or SRO.- ensures they look for safety issues and resolves them.<br/>
              Liasion Officer- Communicates with district personnel or outside agencies.<br/>
              Operations Chief- directs and oversee's the operation and guidies all the resources.<br/>
              Planning Chief-Your job is to solve long and short term problems that are created by the event.<br/>
              Logistics Chief- Arranges for supplies and resources. Ensures everything is set up.
            </div>


            <h3>Command Post Identification</h3>
            <div className="flex-row">				
              As directed by the Incident Commander, the team will meet at the Command Post (CP) to respond to an emergency.     						<br/>
              The CP is the location where the Incident Commander operates during response operations. There is only one CP for each incident or event, but it may change locations during the event.						<br/>
              The CP will be positioned outside of the present and potential hazard zone but close enough to the incident to maintain command.						<br/>
              Depending on the location of the incident, the Command Post could be located in one of the following areas - main office conference room, computer lab, classroom, auditorium, stadium pressbox, parking lot, or another area.						<br/>
              Consideration should be given to the availability of power, Internet, and seating and workspace for up to 15 people.
            </div>

            <div>
              { studentReunificationData.commandPosts.map( (commandPost, index) => {
                  return (
                    <div key={index} className="flex-row row small-cols top-connected">
                        <div className='flex-col'>
                          <span className="row-title">{ commandPost.post }</span>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Location" type="text" maxLength="45" value={commandPost.location || ''} required onChange={(e) => { commandPost.location = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Phone" type="text" maxLength="45" value={commandPost.phone || ''} required onChange={(e) => { commandPost.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                    </div>
                  )
                })}
            </div>


            <h3>School Staff with Special Skills</h3>
            <div className="flex-row">				
            Many other staff within the building can provide assistance in emergency situations. These are staff trained in CPR/First Aid/AEDs, Stop the Bleed, Youth Mental Health First Aid, or who are able to provide translation/interpretation, counseling, assist with threat assessments, etc. 
            </div>

            <div>
              { studentReunificationData.staffSpecialSkills.map( (staffSpecialSkill, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField label="Name" type="text" maxLength="100" value={staffSpecialSkill.name || ''} required onChange={(e) => { staffSpecialSkill.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Title" type="text" maxLength="100" value={staffSpecialSkill.title || ''} required onChange={(e) => { staffSpecialSkill.title = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Location in Building" type="text" maxLength="45" value={staffSpecialSkill.locationInBuilding || ''} required onChange={(e) => { staffSpecialSkill.locationInBuilding = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Cell" type="text" maxLength="45" value={staffSpecialSkill.cell || ''} required onChange={(e) => { staffSpecialSkill.cell = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Skill/Responsibility" type="text" maxLength="45" value={staffSpecialSkill.skill || ''} required onChange={(e) => { staffSpecialSkill.skill = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.staffSpecialSkills = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.staffSpecialSkills}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>
           
           <h2>Addtional Comments</h2>
 <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
          </form>
        </Paper>
    </div>
  )
}