import React from 'react';
import AppContext from '../../context/app'

import safetyEventService from '../../services/safetyEvent';
import institutionService from '../../services/institution';
import userService from '../../services/user';
import SafetyEventEntry from '../../components/SafetyEventEntry'


class SafetyEventModule extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { threatMeetings: [], drills: [], training: [], safetyMeetings: [] };
    this.safetyEventService = new safetyEventService();
    this.institutionService = new institutionService();
    this.userService = new userService();
    this.printPage = this.printPage.bind(this); 
    this.reload = this.reload.bind(this)
  }

  async bindPage(props, context){ 
    try {
      let [safetyEvents, systems, users] = await Promise.all([
        this.safetyEventService.getSafetyEvents(props.match.params.institutionBuildingId),
        this.institutionService.getSystemsByBuildingId(props.match.params.institutionBuildingId),
        this.userService.getInstitutionBuildingUsers(props.match.params.institutionBuildingId)
      ])
      let hasThreatAssessment = systems.find( s => s.system === 'threatAssessment' )
      let threatTeam = users.filter( u => u.viewAllThreats || u.threatTeam)

      let drills = safetyEvents.filter( se => se.type === 'drill' )
      let training = safetyEvents.filter( se => se.type === 'training' )
      let safetyMeetings = safetyEvents.filter( se => se.type === 'safetyMeeting' )
      let threatMeetings = safetyEvents.filter( se => se.type === 'threatMeeting' )
      this.setState({threatMeetings, drills, training, safetyMeetings, hasThreatAssessment, threatTeam})
    }
    catch(err){
      console.log(err)
      this.context.toastError('Loading')
    }
  }

  componentWillReceiveProps(nextProps){
    this.bindPage(nextProps)
  }

  componentWillMount () {
    window.scroll(0,0)

    this.bindPage(this.props)
  }

  reload () {
    this.bindPage(this.props)
  }

  printPage(event){
    window.print();
  }

  render () {
    return (
      <div id='safetyEventModule'>
          <div className="print no-print">
            <span onClick={this.printPage}><i className="fa fa-print"></i> print</span>
          </div>

          <div>
              <h1 className="sub-title">Safety Drills and Training</h1>
              
                <div>
                    The safety drills and training section is designed so the school can maintain a state of readiness in the event of a natural or human-caused threat.  State law mandates that certain drills and training be performed on an annual basis.  This section will assist school leaders in the emergency preparedness phase.
                </div>

                <SafetyEventEntry title="Threat Team Meetings" type="threatMeeting" institutionBuildingId={this.props.match.params.institutionBuildingId} history={this.state.threatMeetings} context={this.context} reload={this.reload} potentialAttendees={this.state.threatTeam} ></SafetyEventEntry>

                <SafetyEventEntry title="Safety Drills" type="drill" institutionBuildingId={this.props.match.params.institutionBuildingId} history={this.state.drills} context={this.context} reload={this.reload} ></SafetyEventEntry>
                
                <SafetyEventEntry title="Safety Training" type="training" institutionBuildingId={this.props.match.params.institutionBuildingId} history={this.state.training} context={this.context} reload={this.reload} ></SafetyEventEntry>

                <SafetyEventEntry title="Safety Meetings" type="safetyMeeting" institutionBuildingId={this.props.match.params.institutionBuildingId} history={this.state.safetyMeetings} context={this.context} reload={this.reload} ></SafetyEventEntry>
          </div>
      </div>
    )
  }

}

export default SafetyEventModule