var fileDownload = require('js-file-download');

export default class fileService {
    downloadAsset(fileName){
      return fetch(`/api/file/downloadAsset/${fileName}`, { method: 'GET', mode: 'cors', credentials: 'include' })
      .then(response => response.blob())
      .then(blob => { 
        fileDownload(blob, fileName);
      })
      .catch( (error) => {
        console.error(error);
        throw Error(error);
      })
    }

    getAssets(folder){
        return fetch(`/api/file/getAssets?folder=${folder || ''}`, { method: 'GET', mode: 'cors', credentials: 'include' })
        .then(response => { 
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json(); 
        })
        .catch( (error) => {
          console.error(error);
          throw Error(error);
        })
    
    }
  
    uploadFile(file, folder, notes){
      return fetch(`/api/file/uploadFile?folder=${folder}&notes=${notes || ''}`, {
        method: 'POST',
        mode: 'cors', 
        credentials: 'include',
        body: file,
      }).then((response) => {
        if (!response.ok)
          throw Error(response.statusText);

        return response
      }) 
      .catch( (error) => {
        console.error(error);
        throw Error(error);
      })
    }
  
    deleteAsset(fileName){
      return fetch('/api/file/deleteAsset', {
        method: 'POST',
        mode: 'cors', 
        credentials: 'include',
        json: true,
        body: JSON.stringify({ fileName }),
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }).then((response) => {
        if (!response.ok)
          throw Error(response.statusText);
        return response
      }) 
      .catch( (error) => {
        console.error(error);
        throw Error(error);
      })
    }
  
  }