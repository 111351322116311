import React, { useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import AddRemove from './AddRemove'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 5

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { buildings: [], aeds: [{}], nearestHospitals: [{}] }
  );


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let [subModuleData, subModulePropertyData] = await Promise.all([
        new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID),
        new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', 2)
      ])
      subModuleData = subModuleData[0]
      subModulePropertyData = subModulePropertyData[0]
      if (subModuleData) 
        subModuleData = JSON.parse(subModuleData.data)
      else 
        subModuleData = {}
      if (subModulePropertyData) {
        subModulePropertyData = JSON.parse(subModulePropertyData.data)
        subModuleData.buildings = subModulePropertyData.buildings
      }
      setStudentReunificationData(subModuleData)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/assignment/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  if (studentReunificationData.buildings && !studentReunificationData.buildings.length)
    return ( <div className="studentReunification"><Paper className="paper"><div className="flex-row"><div className='flex-col'>Please enter your building data in the Property Tab</div></div></Paper></div> )

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
            <h2>Fire Information</h2>

            <h3>Fire Hydrants</h3>
            <div className="flex-row">
                <div className='flex-col full-width'>
                  <div className="textbox-label">
                    Fire hydrants on city or county road right of ways along the perimeter of a campus are 
                    maintained by the city or county having jurisdiction.  Fire hydrants that are definitely
                    on campus property are private hydrants that are owned and maintained by the school district.
                    These private hydrants could be located around buildings, within parking lots, along 
                    driveways, etc.  If there are any private fire hydrants on campus, describe how many and the 
                    areas they are located in (near which buildings).  
                  </div>
                  <div>
                    <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireHydrantComment || ''} onChange={(e) => { studentReunificationData.fireHydrantComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <h3>Fire Sprinklers</h3>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Does your campus have fire sprinklers?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasFireSprinklers === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasFireSprinklers = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasFireSprinklers === 'No'} label="No" onChange={(e) => { studentReunificationData.hasFireSprinklers = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            { studentReunificationData.hasFireSprinklers === 'Yes' ?
            <div>
              <h3>Automatic Fire Sprinklers</h3>
              <div className="flex-row">
                  <div className='flex-col full-width'>
                    <div className="textbox-label">
                    Fire suppression sprinklers are mounted either the ceilings or on top of a wall near the ceiling level and are designed to activate when subjected to heat from a fire.  If your campus has full or partial fire sprinkler coverage, PROVIDE THE FOLLOWING INFORMATION.  (1) Areas of the campus covered vs not covered.  (2) Riser room (Bld number and room number) location(s) which is where the piping and shut off valves are for the sprinkler system are found.  There may be only one riser room for the campus or there could be one for each covered building depending on the design.  (3)  If your campus has on the property perimeter a large pipe that comes out of the ground with two valves on top and the pipe goes back in the ground (double detector check aka back flow preventor) list the location of that device too.  As a general rule, potable water back flow preventors are blue and fire line back flow preventors are red but that is not always the rule 100% of the time.  Contact Tom Rutledge or Steve Skinner if you have any questions or need assistance with this data.
                    </div>
                    <div>
                      <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireSprinklerComment || ''} onChange={(e) => { studentReunificationData.fireSprinklerComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                  </div>
              </div>

              <h3>Fire Department Connection</h3>
              <div className="flex-row">
                  <div className='flex-col full-width'>
                    <div className="textbox-label">
                      If the campus has fire sprinklers, there will be an externally located device somewhere on								
                      campus called the fire department connection (should be labeled as FDC).  This device may								
                      be found on an exterior wall of a building or on a pipe that comes up out of the ground.  								
                      Indicate below where on campus this device is located and add a labeled picture of it in the 								
                      Photos and Documents Section of the campus asset page.								
                    </div>
                    <div>
                      <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireSprinklerDepartmentConnectionComment || ''} onChange={(e) => { studentReunificationData.fireSprinklerDepartmentConnectionComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                  </div>
              </div>

              <h3>Auxiliary Fire Pump</h3>
              <div className="flex-row">
                  <div className='flex-col full-width'>
                    <div className="textbox-label">
                      Campuses consisting of three or more stories in height that have fire sprinkler systems will								
                      have an auxiliary fire pump to boost water pressure to the upper floor levels to maintain								
                      proper pressures for the fire sprinklers on the upper floors.  This pump is often times 								
                      located in or near the riser room that has sprinkler system piping and shut off valves in it.								
                      If the campus has an auxiliary fire pump, describe where it located, how it is powered								
                      (electric, diesel, natural gas or propane) and add a labeled picture of it in the Photos &amp; Documents								
                      Section of the campus's asset page on FSSAT.								
                    </div>
                    <div>
                      <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireSprinklerAuxFirePumpComment || ''} onChange={(e) => { studentReunificationData.fireSprinklerAuxFirePumpComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                  </div>
              </div>

              <h3>Stairwell Standpipes</h3>
              <div className="flex-row">
                  <div className='flex-col full-width'>
                    <div className="textbox-label">
                      Campuses consisting of three or more stories in height may also have standpipe connections
                      in certain stairwells.  This device may be located in a cabinet on the wall in a stairwell or 
                      may simply be an exposed pipe in the corner of the stairwell with a valve and hose 
                      connection on it.  If the campus has a standpipe system, describe which stairwell(s) these 
                      connections can be found in and add a labeled photo in the Photos & Documents section.
                    </div>
                    <div>
                      <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireSprinklerStairwellStandpipesComment || ''} onChange={(e) => { studentReunificationData.fireSprinklerStairwellStandpipesComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                  </div>
              </div>

              <h3>Specialized Fire Suppression Systems</h3>
              <div className="flex-row">
                  <div className='flex-col full-width'>
                    <div className="textbox-label">
                      Some campuses may have specialized (automatic or manual) fire suppression systems
                      for a specific hazard area.  For example, there may be a specialized fire suppression system
                      in a kitchen over a fryer or cooking area.  There could be a specialized fire suppression 
                      system protecting a high value area such as a computer or communications room.  If there 
                      are any specialized systems at your campus, provide where they are located, what the 
                      system is protecting, and add a labeled photo in the Photos and Documents Section.
                    </div>
                    <div>
                      <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireSprinklerSuppressionSystemsComment || ''} onChange={(e) => { studentReunificationData.fireSprinklerSuppressionSystemsComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                  </div>
              </div>
            </div>
            : null }

            <h2>Known Hazardous Materials</h2>

            <h3>Hazardous Materials on Campus</h3>	
            <div className="flex-row">				
              A hazardous material is defined as any material that has the potential to harm what it comes								
              in contact with once it leaves it's container.  Understandably, not every hazardous material								
              can be kept in the flammable storage room.  Campuses that have Agriculture, Automotive,								
              Shop or other such specialty type classes may have hazardous materials in those locations.								
              Hazardous Materials will fall under one of the following classifications:  Explosives, 								
              Gases (Flammable, Non Flammable and Poisonous), Flammable Liquids, Flammable Solids,								
              Oxidizers, Poisons, Radioactives and Corrosives.  On a word document, list the campus								
              hazardous materials, approximate quantities on hand and where they are located.								
              Upload the document titled "2020-2021 Campus Hazardous Materials" to the Photos								
              and Documents Section of FSSAT with this plan.  								
            </div>

            <h3>Flammable Storage Room</h3>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Does your campus have a flammable storage room?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasFlammableStorageRoom === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasFlammableStorageRoom = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasFlammableStorageRoom === 'No'} label="No" onChange={(e) => { studentReunificationData.hasFlammableStorageRoom = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasFlammableStorageRoom === 'Yes' ?
            <div className="flex-row top-connected">
                <div className='flex-col full-width'>
                <TextField label="Where is the flammable storage room located" type="text" maxLength="100" value={studentReunificationData.flammableStorageRoomLocation || ''} required onChange={(e) => { studentReunificationData.flammableStorageRoomLocation = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>
            : null }



            <h2>Medical Aspects</h2>

            <h3>Campus Clinic</h3>
            <div className="flex-row">
                <div className='flex-col full-width'>
                <TextField label="Clinic Location" type="text" value={studentReunificationData.medicalClinicLocation || ''} required onChange={(e) => { studentReunificationData.medicalClinicLocation = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                <TextField label="How many RNs or LPNs are assigned to this campus?" type="number" value={studentReunificationData.numberMedicalPersonnel || ''} required onChange={(e) => { studentReunificationData.numberMedicalPersonnel = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                  <div className="textbox-label">
                  What are the typical types and quantities of normal first aid supplies always kept on hand?								
                  We are not looking for a count of how many bandaids are in the clinic or anything like that.  We are just looking to see that the clinic is well equipped and well stocked to handle normal daily needs.  Does the clinic have a mobile "go bag" stocked that can be carried to a medical call to assist a patient.  Most sites have an accessible wheel chair ready for use if needed to move a patient in lieu of having to carry a patient to the clinic.   								
                  </div>
                  <div>
                    <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.firstAidSupplyComment || ''} onChange={(e) => { studentReunificationData.firstAidSupplyComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                <TextField label="How many STB (Stop the bleed kits) are on campus?" type="number" value={studentReunificationData.numberStopTheBleedKits || ''} required onChange={(e) => { studentReunificationData.numberStopTheBleedKits = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                <TextField label="How many Automatic External Defibrillators (AEDs) on campus" type="number" value={studentReunificationData.numberAutomaticExternalDefibrillators || ''} required onChange={(e) => { studentReunificationData.numberAutomaticExternalDefibrillators = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
            <div className='flex-col full-width'>
              <div className="textbox-label">
              List the locations of all AEDs
              </div>
            </div>
          </div>
            <div>
              { studentReunificationData.aeds.map( (aed, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField disabled={!studentReunificationData.buildings[0].buildingName} select label="Building" type="text" maxLength="45" value={aed.building || ''} required onChange={(e) => { aed.building = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                            { studentReunificationData.buildings.map( (building, index) => {
                              return (<MenuItem key={index} value={building.buildingName}>{building.buildingName}</MenuItem>)
                            })}
                          </TextField>                
                        </div>

                        <div className='flex-col'>
                          <TextField label="Room" type="text" maxLength="45" value={aed.room || ''} required onChange={(e) => { aed.room = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Location" type="text" maxLength="45" value={aed.location || ''} required onChange={(e) => { aed.location = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.aeds = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.aeds}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>

             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
               What are the nearest hospitals to your campus?
               </div>
             </div>
            </div>
            <div>
              { studentReunificationData.nearestHospitals.map( (nearestHospital, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField label="Name" type="text" maxLength="45" value={nearestHospital.name || ''} required onChange={(e) => { nearestHospital.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Phone" type="text" maxLength="45" value={nearestHospital.phone || ''} required onChange={(e) => { nearestHospital.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.nearestHospitals = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.nearestHospitals}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>

           <h2>Addtional Comments</h2>
 <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
            </form>
        </Paper>
    </div>
  )
}