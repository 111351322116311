import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import AddRemove from './AddRemove'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 4

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { otherCloseSchools: [{}], otherCloseHazardousMaterials: [{}] }
  );


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData)
        setStudentReunificationData(JSON.parse(subModuleData.data))
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/hazard/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">

             <h2>Schools</h2>
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
               List other schools (Public, Private, Charter, Adult Based) and daycare centers within a 1 - 1.5 mile radius of your campus
               </div>
             </div>
            </div>

            <div>
            { studentReunificationData.otherCloseSchools.map( (otherCloseSchool, index) => {
                return (
                  <div key={index} className="flex-row row top-connected">
                      <div className='flex-col'>
                        <TextField label="Name" type="text" maxLength="100" value={otherCloseSchool.name || ''} onChange={(e) => { otherCloseSchool.name = e.target.value; setStudentReunificationData(studentReunificationData)}}></TextField>                
                      </div>
                      <div className='flex-col'>
                        <TextField label="Phone" type="text" required={!!otherCloseSchool.phone} maxLength="45" value={otherCloseSchool.phone || ''} onChange={(e) => { otherCloseSchool.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <TextField label="Proximity" type="text" required={!!otherCloseSchool.proximity} maxLength="45" value={otherCloseSchool.proximity || ''} onChange={(e) => { otherCloseSchool.proximity = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.otherCloseSchools = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.otherCloseSchools}></AddRemove>
                      </div>
                  </div>
                )
              })}
            </div>           

            <h2>Hazardous Materials</h2>
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
               Within 1 - 1.5 mile radius of the campus, name any known chemical storage facilities, 
chemical manufacturing facilities, chemical distribution facilities, underground pipelines, 
railways, interstate or major highways where hazardous materials may be in transportation
and could become a threat to the campus during an emergency on their site.
               </div>
             </div>
            </div>

            <div>
            { studentReunificationData.otherCloseHazardousMaterials.map( (otherCloseHazardousMaterial, index) => {
                return (
                  <div key={index} className="flex-row row top-connected">
                      <div className='flex-col'>
                        <TextField label="Name" type="text" maxLength="100" value={otherCloseHazardousMaterial.name || ''} onChange={(e) => { otherCloseHazardousMaterial.name = e.target.value; setStudentReunificationData(studentReunificationData)}}></TextField>                
                      </div>
                      <div className='flex-col'>
                        <TextField label="Phone" type="text" maxLength="45" value={otherCloseHazardousMaterial.phone || ''} onChange={(e) => { otherCloseHazardousMaterial.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <TextField label="Proximity" type="text" maxLength="45" required={!!otherCloseHazardousMaterial.name} value={otherCloseHazardousMaterial.proximity || ''} onChange={(e) => { otherCloseHazardousMaterial.proximity = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.otherCloseHazardousMaterials = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.otherCloseHazardousMaterials}></AddRemove>
                      </div>
                  </div>
                )
              })}
            </div>   

           <h2>Addtional Comments</h2>
 <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>
            
            <div className="flex-row">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
            </form>
        </Paper>
    </div>
  )
}