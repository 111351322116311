import PropTypes from 'prop-types';
import { Fab } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function AddRemove(props) {
  PropTypes.OnUpdate = PropTypes.func.isRequired
  PropTypes.addRemoveArrayIndex = PropTypes.number.isRequired 
  PropTypes.addRemoveArray = PropTypes.array.isRequired 

  const addToArray = () => {
      let addRemoveArray = props.addRemoveArray
      addRemoveArray.push({})
      props.OnUpdate(addRemoveArray)
  }

  const removeFromArray = () => {
    let addRemoveArray = props.addRemoveArray
    addRemoveArray.splice(props.addRemoveArrayIndex, 1);
    props.OnUpdate(addRemoveArray)
}

  return (
      <div className="AddRemove flex-row">      
          { props.addRemoveArrayIndex === props.addRemoveArray.length-1 ?
                <div className="flex-col add">
                    <Fab size="small" color="primary" aria-label="add" onClick={addToArray}>
                        <AddIcon/> 
                    </Fab>
                </div>
            : null }

            { props.addRemoveArrayIndex > 0 && props.addRemoveArrayIndex+1 > 1 ?
                <div className="flex-col">
                    <Fab size="small" aria-label="add" onClick={(e) => removeFromArray()}>
                        <RemoveIcon /> 
                    </Fab>
                </div>
            : null }
      </div>
  )
}
