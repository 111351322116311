import React, { useEffect, useContext } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import { Icon, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

export default function TabRouter(props) {
  const location = useLocation()
  const { institutionBuildingId } = useParams()
  const context = useContext(AppContext)
  const [value, setValue] = React.useState(0);

  const tabs = [
    { label: 'Exterior', to: `/emergencyManagement/sitevisit/exterior/${institutionBuildingId}` },
    { label: "Interior", to: `/emergencyManagement/sitevisit/interior/${institutionBuildingId}` },
    { label: "CLPS", to: `/emergencyManagement/sitevisit/clps/${institutionBuildingId}` },
    { label: "Other", to: `/emergencyManagement/sitevisit/other/${institutionBuildingId}` }
  ]


  useEffect( () => {
    let tabIndex = tabs.findIndex( (t) => location.pathname === t.to )
    setValue(tabIndex >=0 ? tabIndex : 0);
  },[location])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNotify = async () => {
    try {
      await new emergencyManagementService().notify(institutionBuildingId, 'siteVisit')
      context.toastSuccess('Notificaiton Sent')
    }
    catch(err){
      console.log(err)
      context.toastError('Notificaiton Error')
    }
  }

  return (
    <div>
      <div className="siteVisit">

        <div className="flex-row tab-action-row">
          <div className="flex-col">
            <Box sx={{ width: '100%' }}>
                <Tabs value={value} onChange={handleChange}>
                  { tabs.map( (tab, index) => {
                      return (<Tab key={index} label={tab.label} component={Link} to={tab.to} /> )
                    })
                  }
                </Tabs>
            </Box>
          </div>

          <div className="flex-col">
              <div className="flex-row actions">
                  <span className="action flex-col">
                    <Button variant="outlined" onClick={(e) => { handleNotify(institutionBuildingId) }}><Icon className="fa fa-bell"/>Notify Team</Button>
                  </span>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}