import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Button, Icon, TextField, Checkbox, FormControlLabel  } from '@mui/material';
import moment from 'moment'
import AppContext from '../../context/app'
import emergencyManagementService from '../../services/emergencyManagement'
import institutionService from '../../services/institution';
import ContentEditable from 'react-contenteditable'



export default function Component(props) {
  const context = useContext(AppContext)
  const { institutionBuildingId, policyId } = useParams()

  const [policy, setPolicy] = useState()
  const [comments, setComments] = useState()


  useEffect( async () => {
    window.scroll(0,0)

    try {
      let building = await new institutionService().getBuilding(institutionBuildingId)
      let policies = await new emergencyManagementService().getSubPolicies(institutionBuildingId, building.institutionGroupId)
      let savedPolicy = await new emergencyManagementService().getPolicy(institutionBuildingId, policyId)
      let policy = policies.find(pg => pg.policyId === +policyId )
      setPolicy(policy)
      setComments(savedPolicy.comments)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
    }
  },[])


  let handleContentChange = (event, subPolicy) => {
    subPolicy = policy.subPolicies.find( sp => sp.subPolicyId === subPolicy.subPolicyId )
    subPolicy.contentChanged = true
    subPolicy.content = event.target.value
    setPolicy(policy)
  }

  let handleAcknowledge = async (event, subPolicy) => {
    event.preventDefault();

    try {
      let savePolicy = { institutionBuildingId , subPolicyId: subPolicy.subPolicyId, content: subPolicy.contentChanged ? subPolicy.content : null }
      await new emergencyManagementService().saveSubPolicy(savePolicy)

      //update policies
      let saveSubPolicy = policy.subPolicies.find( sp => sp.subPolicyId === subPolicy.subPolicyId)
      saveSubPolicy.isAcknowledged = true;
      saveSubPolicy.acknowledgeFirstName = context.user.firstName
      saveSubPolicy.acknowledgeLastName = context.user.lastName
      saveSubPolicy.timeStamp = moment().format('MM/DD/YYYY')
      setPolicy(policy)
      context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Acknowledgement could not save')
    }
  }

  let handleSave = async (event) => {
    event.preventDefault();

    try {
      let policy = { institutionBuildingId, policyId, comments }
      await new emergencyManagementService().savePolicy(policy)
      context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Policy could not save')
    }
  }


  return (
      <div id="policy">
            { policy ?
              <div>
                <h1 className="sub-title">{policy.title}</h1>
                { policy.justification ?
                  <div className="justification">
                    Justification: {policy.justification}
                  </div>
                : null }

                {
                 policy.subPolicies.map( (subPolicy, index) => {
                    return (  
                      <Paper className="paper">
                           <ContentEditable
                              html={subPolicy.content}
                              onChange={(e) => handleContentChange(e, subPolicy)}
                              disabled={subPolicy.isAcknowledged}
                          />
                          { subPolicy.isAcknowledged ?
                            <div className="flex-row acknowledged">
                              <div className="flex-col complete-check-container"><span><i className='fa fa-check-circle'></i></span></div>
                              <div className="flex-col acknowledgedName">{ subPolicy.acknowledgeFirstName } { subPolicy.acknowledgeLastName } </div>
                              <div className="flex-col acknowledgedDate">{ moment(subPolicy.timeStamp).format('MM/DD/YYYY') }</div>
                            </div>
                          : 
                            <div>
                              <FormControlLabel label="Acknowledge" control={<Checkbox onChange={(e) => handleAcknowledge(e, subPolicy)}/>} />
                            </div>                          
                          }
                     </Paper>
                    );
                  })
                }

                <div>
                  <TextField multiline maxRows={5} name="notes" type="text" inputProps={{maxLength: 2000}} value={comments || ''} label="Comments" onChange={(e) => setComments(e.target.value)} />
                </div>
                <div className='section'>
                  <Button variant="outlined" onClick={handleSave}><Icon className="fa fa-save" />Save</Button>
                </div>
              </div>
            : null }
        </div>
    )
}