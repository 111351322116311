import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField } from '@mui/material';
import AddRemove from './AddRemove'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 8

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { alertCampusResponsiblities: [
      { title: 'SRO/SSG',	responsibility:  'Conduct a walk around the school, share public safety threat info with staff' },
      { title: 'Maintenance',	responsibility:  'Walk/Drive the School Property Perimeter	' },
      { title: 'Teachers', responsibility: 'Be visible, and in hallways during passing' },
      { title: 'Admin',	responsibility: 'Share all relevent public safety info, videos, pictures, with staff via Crisis-Go' }
    ], 
    dismissalResponsiblities: [
      { responsibility:  'Notify District Office'},
      { responsibility:  'Turn off the Bells'},
      { responsibility:  'Front office- Greeter'},
      { responsibility: 'Cafeteria-manage student release'},
      { responsibility:  'Manage incidents as they arise in/out of the building'},
      { responsibility:  'Manage outside operations'},
      { responsibility: 'Supervise upstairs and downstairs '},
      { responsibility: 'Problem solve '},
      { responsibility: 'Incident Commander-filter and desciminate informaiton'}
    ], 
    medicalEmergencyResponsiblities: [
      { responsibility:  'Notify District Office' },
      { responsibility:  'Call 911' },
      { responsibility: 'Activate the AED team and Bring "Stop the bleed kit" , if applicable' },
      { responsibility: 'Bring "Stop the bleed kit" , if applicable' },
      { responsibility:  'Meet ambulance outside' },
      { responsibility:  'Report to the scene to secure and make sure area is safe, then assist admin and medical staff' }
    ]
    });


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData)
        setStudentReunificationData(JSON.parse(subModuleData.data))
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/photo/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
              <div className="flex-row">
                  <div className='flex-col full-width'>
                    <b>Utilize the new Site Threat Continuum card to be posted in every room-  These are assignments you should consider.</b>
                  </div>
              </div>

            <h3>Alert Campus</h3>
            <div>
              { studentReunificationData.alertCampusResponsiblities.map( (alertCampusResponsiblity, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField label="Name" type="text" maxLength="45" value={alertCampusResponsiblity.name || ''} required onChange={(e) => { alertCampusResponsiblity.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col large-col'>
                          <TextField label="Responsibility" type="text" maxLength="100" value={alertCampusResponsiblity.responsibility || ''} required onChange={(e) => { alertCampusResponsiblity.responsibility = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.alertCampusResponsiblities = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.alertCampusResponsiblities}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>

            <h3>Controlled Dismissal Administration Responsibilities</h3>
            <div>
              { studentReunificationData.dismissalResponsiblities.map( (dismissalResponsiblity, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField label="Name" type="text" maxLength="45" value={dismissalResponsiblity.name || ''} required onChange={(e) => { dismissalResponsiblity.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col large-col'>
                          <TextField label="Responsibility" type="text" maxLength="100" value={dismissalResponsiblity.responsibility || ''} required onChange={(e) => { dismissalResponsiblity.responsibility = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.dismissalResponsiblities = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.dismissalResponsiblities}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>

            <h3>Medical Emergency</h3>
            <div>
              { studentReunificationData.medicalEmergencyResponsiblities.map( (medicalEmergencyResponsiblity, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField label="Name" type="text" maxLength="45" value={medicalEmergencyResponsiblity.name || ''} required onChange={(e) => { medicalEmergencyResponsiblity.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col large-col'>
                          <TextField label="Responsibility" type="text" maxLength="100" value={medicalEmergencyResponsiblity.responsibility || ''} required onChange={(e) => { medicalEmergencyResponsiblity.responsibility = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.medicalEmergencyResponsiblities = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.medicalEmergencyResponsiblities}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>
           
           
           <h2>Addtional Comments</h2>
            <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
          </form>
        </Paper>
    </div>
  )
}