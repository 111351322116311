import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import AddRemove from './AddRemove'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 2

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { buildings: [{}], elevators: [{}], intrusionAlarms: [{}] }
  );


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData)
        setStudentReunificationData(JSON.parse(subModuleData.data))
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/utility/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
            <h2>Campus Information</h2>
            <div className="flex-row">
                <div className='flex-col full-width'>
                <TextField label="Campus Size" type="text" value={studentReunificationData.campusSize || ''} required onChange={(e) => { studentReunificationData.campusSize = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Is the outer perimeter of the property entirely fenced?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.perimeterFenced === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.perimeterFenced = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.perimeterFenced === 'No'} label="No" onChange={(e) => { studentReunificationData.perimeterFenced = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.perimeterFenced === 'No' ?
            <div className="flex-row top-connected">
                <div className='flex-col full-width'>
                <TextField label="Describe the perimeter not fenced" type="text" multiline={true} maxLength="300" value={studentReunificationData.perimeterFencedComment || ''} required onChange={(e) => { studentReunificationData.perimeterFencedComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>
            : null }

            <div className="flex-row">
                <div className='flex-col full-width'>
                  <div className="textbox-label">
                    Describe any water features (meaning ponds or other bodies of water) or areas that have the  								
                    ability to frequently hold several inches of water on the property.  List location on campus below 								
                    and add a labeled photo of this area in the Photos and Documents Section on school's asset page.								
                    Also indicate if there is 6 foot fencing in place protecting any water feature area.  
                  </div>
                  <div>
                    <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.waterFeatureComment || ''} onChange={(e) => { studentReunificationData.waterFeatureComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>
								
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Are all vehicle access points (driveways) to the campus able to be secured with gates to prevent vehicular traffic accessing the campus?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.gatedAccess === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.gatedAccess = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.gatedAccess === 'No'} label="No" onChange={(e) => { studentReunificationData.gatedAccess = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.gatedAccess === 'No' ?
            <div className="flex-row top-connected">
                <div className='flex-col full-width'>
                <div className="textbox-label">
                  Describe the driveways and parking areas not securable with gates.  Add labeled	photos of driveway gates / entrances to the Photos and Documents Section on asset tab. 
                  </div>
                <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.gatedAccessComment || ''} required onChange={(e) => { studentReunificationData.gatedAccessComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>
            : null }

            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Which best describes the classroom buildings at your school.  (excluding portables, modulars, offices, media center, cafeteria, etc.)?</FormLabel>
                  <RadioGroup>
                    <FormControlLabel value="All classrooms at this campus are of exterior / open concept" control={<Radio required />} checked={studentReunificationData.campusConcept === "All classrooms at this campus are of exterior / open concept"} label="All classrooms at this campus are of exterior / open concept" onChange={(e) => { studentReunificationData.campusConcept = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="All classrooms at this campus are of interior / closed concept" control={<Radio required />} checked={studentReunificationData.campusConcept === "All classrooms at this campus are of interior / closed concept"} label="All classrooms at this campus are of interior / closed concept" onChange={(e) => { studentReunificationData.campusConcept = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                    <FormControlLabel value="This campus has both interior and exterior concept classrooms" control={<Radio required />} checked={studentReunificationData.campusConcept === "This campus has both interior and exterior concept classrooms"} label="This campus has both interior and exterior concept classrooms" onChange={(e) => { studentReunificationData.campusConcept = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <h3>Buildings</h3>
            <div className="flex-row">
                <div className='flex-col full-width'>
                  Buildings
                </div>
            </div>

            { studentReunificationData.buildings.map( (building, index) => {
                return (
                  <div key={index} className="flex-row row top-connected">
                    <div className='flex-col'>
                      <TextField label="Building #" type="number" value={building.buildingNumber || ''} required onChange={(e) => { building.buildingNumber = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                    <div className='flex-col'>
                      <TextField label="Building Name" type="text" value={building.buildingName || ''} required onChange={(e) => { building.buildingName = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                    <div className='flex-col'>
                      <TextField label="Stories" type="number" value={building.buildingStories || ''} required onChange={(e) => { building.buildingStories = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                    <div className='flex-col'>
                      <TextField label="Primary Purpose" type="text" maxLength="45" value={building.buildingPrimaryPurpose || ''} required onChange={(e) => { building.buildingPrimaryPurpose = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    </div>
                    <div className='flex-col'>
                      <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.buildings = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.buildings}></AddRemove>
                    </div>
                  </div>	
                )
            })}
            
            <div className="flex-row">
                <div className='flex-col full-width'>
                <TextField label="Number of Modular Buildings on Campus" type="number" value={studentReunificationData.modularBuildingNumber || ''} required onChange={(e) => { studentReunificationData.modularBuildingNumber = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>
								

            <div className="flex-row">
                <div className='flex-col full-width'>
                <div className="textbox-label">
                Describe any basement or below grade access.  Include which building number(s) have below grade access and what the room number / door number is (if available).  Describe what this below grade space is used for (such as storage, classrooms, etc.,)  Include data if there is more than one door to this below grade space.								
                  </div>
                <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.belowGradeComment || ''} onChange={(e) => { studentReunificationData.belowGradeComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                <div className="textbox-label">
                Describe any building on campus that has either an internal access way to a roof hatch or any external permanently fixed ladders designed for providing roof access.  Provide location (including building numbers and room numbers) and describe each point of roof access.								
                  </div>
                <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.roofAccessComment || ''} onChange={(e) => { studentReunificationData.roofAccessComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                <div className="textbox-label">
                Describe any building on campus that has emergency escape window accessibility.  Open one of the escape windows and add a labeled picture (from both inside and outside) to the Photos and Documents Section of the campus asset page in FSSAT								                  </div>
                <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.windowAccessComment || ''} onChange={(e) => { studentReunificationData.windowAccessComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                  Elevators
                </div>
            </div>	
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Are there elevators on campus?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasElevatorsOnCampus === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasElevatorsOnCampus = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasElevatorsOnCampus === 'No'} label="No" onChange={(e) => { studentReunificationData.hasElevatorsOnCampus = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            { studentReunificationData.hasElevatorsOnCampus === 'Yes' ?
            <div>
              { studentReunificationData.elevators.map( (elevator, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField disabled={!studentReunificationData.buildings[0].buildingName} select label="Building" type="text" maxLength="45" value={elevator.elevatorBuilding || ''} required onChange={(e) => { elevator.elevatorBuilding = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                            { studentReunificationData.buildings.map( (building, index) => {
                              return (<MenuItem key={index} value={building.buildingName}>{building.buildingName}</MenuItem>)
                            })}
                          </TextField>                
                        </div>

                        <div className='flex-col'>
                          <TextField label="Room" type="text" maxLength="45" value={elevator.elevatorRoom || ''} required onChange={(e) => { elevator.elevatorRoom = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField select required label="Operational" value={elevator.elevatorOperation || ''} onChange={(e) => { elevator.elevatorOperation = e.target.value; setStudentReunificationData(studentReunificationData) }}>                      
                            <MenuItem value="Always">Always</MenuItem>
                            <MenuItem value="Key Control">Key Control</MenuItem>
                          </TextField>                
                        </div>
                        <div className='flex-col'>
                          { elevator.elevatorOperation === 'Key Control' ?
                            <TextField label="Key Control Holder" type="text" maxLength="100" value={elevator.elevatorKeyHolder || ''} required onChange={(e) => { elevator.elevatorKeyHolder = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                          : null }
                        </div>
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.elevators = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.elevators}></AddRemove>
                        </div>
                    </div>
                  )
                })}
              </div>
              : null }

          { studentReunificationData.elevators.length > 0?
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Is there a small metal box mounted next to main fire alarm panel that holds the elevator	rod key for emergency responders to have access to?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.elevatorRodKey === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.elevatorRodKey = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="Elsewhere on Campus" control={<Radio required />} checked={studentReunificationData.elevatorRodKeyOnCampus === 'Elsewhere on Campus'} label="Elsewhere on Campus" onChange={(e) => { studentReunificationData.elevatorRodKeyOnCampus = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                    <FormControlLabel value="None on Campus" control={<Radio required />} checked={studentReunificationData.elevatorRodKeyOnCampus === 'None on Campus'} label="None on Campus" onChange={(e) => { studentReunificationData.elevatorRodKeyOnCampus = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            : null }

								
								
            <h2>Keysafe and Alarm System Information</h2>
           
            <div className="flex-row">
                <div className='flex-col full-width'>
                <div className="textbox-label">
                The Knox Box should be externally mounted on campus where a set of master keys for your campus are kept.</div>
                <TextField label="Location of the current Blue and Knox Boxes" type="text" multiline={true} maxLength="300" value={studentReunificationData.locationKnoxBox || ''} onChange={(e) => { studentReunificationData.locationKnoxBox = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <h3>Fire Alarm Panels</h3>
            <div className="flex-row">
                <div className='flex-col full-width'>
                  The main fire alarm panel for this campus is in Building 
                </div>
            </div>	
						<div className="flex-row row top-connected">
              <div className='flex-col'>
                <TextField disabled={!studentReunificationData.buildings[0].buildingName} select label="Building" type="text" maxLength="45" value={studentReunificationData.alertMainBuildingName || ''} required onChange={(e) => { studentReunificationData.alertMainBuildingName = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                  { studentReunificationData.buildings.map( (building, index) => {
                    return (<MenuItem key={index} value={building.buildingName}>{building.buildingName}</MenuItem>)
                  })}
                </TextField>                 
              </div>
              <div className='flex-col'>
                  <TextField label="Room" type="text" maxLength="45" value={studentReunificationData.alarmMainRoom || ''} required onChange={(e) => { studentReunificationData.alarmMainRoom = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
              </div>
            </div>

            <div className="flex-row">
              Take a picture of the main fire alarm panel, label it as Main Fire Alarm Panel Bld ___ Rm ___ and 								
              add that picture to the campus asset page in FSSAT.  If the campus has two fire alarm systems								
              tied together, label your pictures with building numbers and room numbers for all panels.								
              An auxiliary fire panel is a duplicate (usually smaller in size) panel that still allows for 								
              the reading of the alarm location, silencing and resetting of the fire alarm.  For example, 								
              the main fire alarm panel may be located in an electrical room, however there may be 								
              a small panel in the lobby of the main office to read where the fire alarm is coming from.								
              It is also possible for a campus to have more than one main panel.  When a campus has 								
              a new building or wing added, sometimes it will come with it's own main fire alarm panel								
              located somewhere in the new addition.  There will then be a small auxiliary panel for 								
              that new system placed next to the original main fire alarm panel for the campus so both								
              systems can be viewed at the same time.  The alarms are connected so that no matter 								
              where on campus the alarm is received from, both alarms will sound together as one alarm.								
              								
            </div>
            <div className="flex-row top-connected">
                <div className='flex-col full-width'>
                <div className="textbox-label">
                  Provide any information about your campus having auxiliary fire alarm panels or secondary systems added on to the original alarm.
                </div>
                <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.fireAlarmAuxComment || ''} onChange={(e) => { studentReunificationData.fireAlarmAuxComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <h3>Intrusion Alarm Panels</h3>

            <div className="flex-row">
              List all of the system numbers and locations of the intrusion alarm panels on campus.								
              It is possible to have more than one panel for the same system in some cases.  When this								
              occurs, list the system number twice and then each location where they can be found.								
              If the panel is located within a cabinet or not so visible location when the room is entered,								
              give a brief description of how to locate the panel.  If you are not sure of all panel locations, 								
              get with your head custodian for assistance.  Each intrusion alarm panel should have the system								
              number for each panel showing either with a label from a label maker or written on with a sharpie.								
              If you cannot locate a system number on a panel, please get with you SSG/SRO to assist.								
              Please take a black sharpie and write the number on 								
              one of the top corners of the panel at that time.	
            </div>	
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Are there intrusion alarms on campus?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasIntrusionAlarmsOnCampus === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasIntrusionAlarmsOnCampus = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasIntrusionAlarmsOnCampus === 'No'} label="No" onChange={(e) => { studentReunificationData.hasIntrusionAlarmsOnCampus = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasIntrusionAlarmsOnCampus === 'Yes' ? 
            <div>
              { studentReunificationData.intrusionAlarms.map( (intrusionAlarm, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                      <div className='flex-col'>
                          <TextField label="System #" type="number" value={intrusionAlarm.intrusionAlarmSystemNumber || ''} required onChange={(e) => { intrusionAlarm.intrusionAlarmSystemNumber = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <TextField disabled={!studentReunificationData.buildings[0].buildingName} select label="Building" type="text" maxLength="45" value={intrusionAlarm.buildingName || ''} required onChange={(e) => { intrusionAlarm.buildingName = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                          { studentReunificationData.buildings.map( (building, index) => {
                            return (<MenuItem key={index} value={building.buildingName}>{building.buildingName}</MenuItem>)
                          })}
                        </TextField>               
                      </div>
                      <div className='flex-col'>
                          <TextField label="Room" type="text" maxLength="45" value={intrusionAlarm.intrusionAlarmRoom || ''} required onChange={(e) => { intrusionAlarm.intrusionAlarmRoom = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                          <TextField label="Location" type="text" maxLength="100" value={intrusionAlarm.intrusionAlarmLocation || ''} required onChange={(e) => { intrusionAlarm.intrusionAlarmLocation = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                      <div className='flex-col'>
                        <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.intrusionAlarms = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.intrusionAlarms}></AddRemove>
                      </div>
                    </div>	
                  )
              })}
            </div>
            : null }


            <h3>Campus Flooding Issues</h3>

            <div className="flex-row">
              Historically, during heavy rains or storms, what areas of your campus have had issues in regards to flooding where access to particular buildings may become a problem?  Has flood waters previously entered these buildings?  Were defensive measures taken such as sandbags and was the effort and protection from those measures successful?
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                  <TextField label="Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.floodIssueComment || ''} onChange={(e) => { studentReunificationData.floodIssueComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

            <div className="flex-row">
              Historically, during heavy rains or storms, are there any roadways near the campus and/or parking lots around the campus that become unusable?
            </div>

            <div className="flex-row">
                <div className='flex-col full-width'>
                  <TextField label="Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.floodRoadComment || ''} onChange={(e) => { studentReunificationData.floodRoadComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>

           <h2>Addtional Comments</h2>
 <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
            </form>
        </Paper>
    </div>
  )
}