import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper } from '@mui/material';
import FileViewer from '../../../components/FileViewer'

import emergencyManagementService from '../../../services/emergencyManagement'
import { Camera as CameraIcon } from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone'
import AppContext from '../../../context/app'

const FILETYPE = 'siteVisit'

export default function Component(props) {
  const context = useContext(AppContext)
  const { institutionBuildingId } = useParams()

  const [files, setFiles] = useState([])
  const [assetNames, setAssetNames] = useState([])
  const [fileViewerOpen, setFileViewerOpen] = useState(false)
  const [fileViewerAssetIndex, setFileViewerAssetIndex ] = useState()

  const bindAssets = async () => {
    let assetNames = await new emergencyManagementService().getAssetNames(institutionBuildingId, FILETYPE, props.subModuleId)
    const imageExt = ['.png','.gif','.jpg','.jpeg']
    let endsWithAny = (suffixes, string) => {
      return suffixes.some(function (suffix) {
            return string.toLowerCase().endsWith(suffix);
      });
    }
    assetNames.forEach( (a) => {
      a.isImage = endsWithAny(imageExt, a.fileName)
    })
    setAssetNames(assetNames)
  }

  useEffect( async () => {
    window.scroll(0,0)
    try {
      bindAssets()
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const handleUploadFile = async (files) => {
    if (!files.length)
      return

    try {
      async function uploadFiles() {
        for (const file of files) {
          let data = new FormData()
          data.append('file', file.file)
          await new emergencyManagementService().uploadFile(+institutionBuildingId, FILETYPE, data, null, props.subModuleId)
        }
      }

      await uploadFiles.bind(this)()

      //reload the images
      bindAssets()
      setFiles([])
      context.toastSuccess('File uploaded')
    }
    catch(err){
      console.log(err)
      context.toastError('File could not be uploaded')
      setFiles([])
    }
  }

  const handleDeleteImage = async (assetId) => {
    try {
      await new emergencyManagementService().deleteAsset(assetId )
      bindAssets()
      context.toastSuccess('File deleted')
    }
    catch(err){
      console.log(err)
      context.toastError('File could not be deleted')
    }

  }

  return (
    <>
      <FileViewer open={fileViewerOpen} onClose={e => setFileViewerOpen(false)} files={assetNames} index={fileViewerAssetIndex} institutionBuildingId={institutionBuildingId} fileType={FILETYPE} ></FileViewer>
      <div className="siteVisit">                             
          <div className="flex-row">
            { assetNames.map( (fileName, index) => {
              return(
                <div className="flex-col uploaded-file-container" key={index}>
                  { fileName.isImage ?
                    <img  
                      src={`/api/emergencyManagement/getAsset?assetId=${fileName.emergencyManagementAssetId}`}
                      className="uploaded-file"
                      data-fileName={fileName.fileName}
                      alt={ fileName.notes || fileName.fileName  }
                      title={fileName.notes || fileName.fileName }
                      onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                    />
                  :
                    <span 
                      className="fa fa-file uploaded-file"
                      data-fileName={fileName.fileName}
                      alt={ fileName.notes || fileName.fileName  }
                      title={fileName.notes || fileName.fileName }
                      onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                    />
                  }

                  {!props.disableDelete ?
                  <span>
                      <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteImage(fileName.emergencyManagementAssetId) }}></i>
                  </span>
                  : null }
                <div className="uploaded-file-caption">
                  {fileName.notes || fileName.fileName}
                </div>
                </div>
              )
              }) 
            }
          </div>

          <div className="dropzonearea flex-row">                  
            <DropzoneAreaBase
              acceptedFiles={['image/*']}
              Icon={CameraIcon}
              dropzoneText={"Add a Picture"}
              filesLimit={30}
              maxFileSize={30000000}
              onAdd={(fileObjs) => { let newFiles = files.concat(fileObjs); setFiles(newFiles);  handleUploadFile(newFiles)}}
              fileObjects={files}
              />
          </div>
    </div>
  </>
  )
}











