import React from 'react';
import AppContext from '../../context/app'

import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import userService from '../../services/user';
import institutionService from '../../services/institution';
import { Paper, Button, Icon } from '@material-ui/core';

class List extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { institutionBuildings: [] }
    this.history = createBrowserHistory();
    this.userService = new userService();
    this.institutionService = new institutionService();
    this.downloadManagedExcel = this.downloadManagedExcel.bind(this);
  }

  async componentDidMount () {
    window.scroll(0,0)
    this.context.updateInstitutionBuildingId(null)

    try {
      let [institutions, institutionBuildings] = await Promise.all([
        this.institutionService.getAll(),
        this.institutionService.getAllBuildings()
      ])

      if (institutions.length === 1 && institutionBuildings.length === 1)
        this.history.push(`/emergencyManagement/detail/${institutionBuildings[0].institutionBuildingId}`)

      this.setState( { institutions, institutionBuildings } );
    }
    catch(err){
      console.log(err)
      this.context.toastError('Loading')
    }
  }

  downloadManagedExcel(event){
    this.emergencyManagementService.downloadManagedExcel()
  }


  render () {
    return (
      <div id='emergencyManagementPlans'>
            { this.context.user ?
              <div>
                <div>
                  { this.state.institutionBuildings.length ?
                    <div className="emergencyManagementPlans">
                     
                      {
                          this.state.institutionBuildings.map( (institutionBuilding) => {
                              return (
                                <Link className="emergencyManagementPlan-link" key={institutionBuilding.institutionBuildingId} to={`/emergencyManagement/detail/${institutionBuilding.institutionBuildingId}`}>
                                  <Paper className="flex-row emergencyManagementPlan">
                                      <span className="flex-col emergencyManagementPlanName">
                                      
                                        <div className='flex-row'>
                                          <span className='flex-col'><i className="planIcon fa fa-map"></i></span>
                                          <div  className='flex-col'>
                                            <div>
                                              <span dangerouslySetInnerHTML={ { __html: institutionBuilding.buildingName} }></span>  
                                              { this.state.institutions.length > 1 ?
                                                <div className=" institutionName" dangerouslySetInnerHTML={ { __html: `${institutionBuilding.institutionName}`} }></div>
                                              : null }
                                            </div>  
                                          </div>                                     
                                        </div>
                                        
                                      </span>
                                      
                                      <span className="flex-col">
                                        <Button variant="outlined">
                                          <Icon className="fa fa-edit" />Open
                                        </Button>
                                      </span>
                                  </Paper>
                                </Link>
                              );
                          })
                      }
                    </div>
                  :
                    null
                  }
                </div>
            </div>
        : null }
      </div>
    )
  }

 
}


export default List
