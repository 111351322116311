import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Icon, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

import AppContext from '../context/app'

import emergencyManagementService from '../services/emergencyManagement'

File.propTypes = {
  assetNames: PropTypes.array
};

export default function File(props) {
  const context = useContext(AppContext)
  const [assetNames, setAssetNames] = useState([])

  useEffect( async () => {
    setAssetNames(props.assetNames)
  },[])

  const handleDownloadFile = (emergencyManagementAssetId, fileName) => {
    new emergencyManagementService().downloadAsset(emergencyManagementAssetId, fileName)  
  }

  const getImageSource = (file) => {
    let imageSource = `/api/emergencyManagement/getAsset?assetId=${file.emergencyManagementAssetId}`
    return imageSource
  }

  return (

    <div className="fileviewer">
      <Dialog className="fileviewer-dialog" fullWidth={true} open={props.open} onClose={props.onClose} aria-labelledby="simple-dialog-title">
          { props.title ?
            <DialogTitle id="dialog-title">{props.title}</DialogTitle>
          : null }
          <Carousel autoPlay={false} index={props.index}>
            { props.files.map( (file, index) => {
              return(
                <div className="uploaded-file-container" key={index}>
                  { file.isImage ?
                    <img 
                      src={getImageSource(file)}
                      className="uploaded-file"
                      alt={ file.notes || file.fileName  }
                      title={file.notes || file.fileName }
                      
                    />
                  :
                    <span 
                      className="fa fa-file uploaded-file uploaded-file-file"
                      alt={ file.notes || file.fileName  }
                      title={file.notes || file.fileName }
                    />
                  }
                  <div className="uploaded-file-caption">
                    {file.notes ||  file.fileName}
                  </div>

                  <div className="download-button">
                    <Button variant="outlined" onClick={ (e) => handleDownloadFile(file.emergencyManagementAssetId, file.fileName)}>
                      <Icon className="fa fa-download"/>Download
                    </Button>
                  </div>
                </div>
              )
              }) 
              }
          </Carousel>
      </Dialog> 
    </div>
  )
}