import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField, RadioGroup, Radio, FormControl, FormControlLabel, FormLabel, MenuItem } from '@mui/material';
import AddRemove from './AddRemove'
import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'
import institutionService from '../../../services/institution';

const SUBMODULE_ID = 1

export default function Detail(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [building, setBuilding] = useState(null)
  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      contacts: [{ role: 'Principal'},{ role: 'Assistant Principal'},{ role: 'Plant Manager' }],
      reunificationSites: [{ type: 'Primary'},{ type: 'Secondary'}]
    }
  );


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let [building, subModuleData] = await Promise.all([
        new institutionService().getBuilding(institutionBuildingId),
        new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID)
      ])
      setBuilding(building)
      subModuleData = subModuleData[0]
      if (subModuleData)
        setStudentReunificationData(JSON.parse(subModuleData.data))
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/property/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="studentReunification">
      { building ?
        <Paper className="paper">
           <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
            <div className="flex-row">
              <div className='flex-col full-width'>{ building.buildingName }</div>
            </div>
            <div className="flex-row">
              <div className='flex-col full-width'>{ building.buildingAddress }</div>
            </div>

            <div className="flex-row">
            <div className='flex-col full-width'>
              <div className="textbox-label">
                Contacts: 
              </div>
            </div>
          </div>
            <div>
              { studentReunificationData.contacts.map( (contact, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col med-col'>
                          <TextField label="Role" type="text" required maxLength="100" value={contact.role || ''} onChange={(e) => { contact.role = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>

                        <div className='flex-col'>
                          <TextField label="Name" type="text" required maxLength="100" value={contact.name || ''} onChange={(e) => { contact.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Phone" type="text" required maxLength="20" value={contact.phone || ''} onChange={(e) => { contact.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.contacts = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.contacts}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>

            <div className="flex-row">
            <div className='flex-col full-width'>
              <div className="textbox-label">
                Where are the reunification sites? 
              </div>
            </div>
          </div>
            <div>
              { studentReunificationData.reunificationSites.map( (reunificationSite, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField select label="Type" type="text" value={reunificationSite.type || ''} onChange={(e) => { reunificationSite.type = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                              <MenuItem key={index} value="Primary">Primary</MenuItem>
                              <MenuItem key={index} value="Secondary">Secondary</MenuItem>
                              <MenuItem key={index} value="Additional">Additional</MenuItem>
                          </TextField>                
                        </div>
                        <div className='flex-col'>
                          <TextField label="Name" type="text" maxLength="100" value={reunificationSite.name || ''} onChange={(e) => { reunificationSite.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>

                        <div className='flex-col'>
                          <TextField label="Address" type="text" maxLength="100" value={reunificationSite.address || ''} onChange={(e) => { reunificationSite.address = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Phone" type="text" maxLength="20" value={reunificationSite.phone || ''} onChange={(e) => { reunificationSite.phone = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>

                        <div className='flex-col'>
                          <TextField label="Email" type="email" maxLength="255" value={reunificationSite.email || ''} onChange={(e) => { reunificationSite.email = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.reunificationSites = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.reunificationSites}></AddRemove>
                        </div>
                    </div>
                  )
                })}
            </div>
            Please upload your MOU to the Photos/Files section 

                  
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Hurricane Shelter School</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hurricaneShelterSchool === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hurricaneShelterSchool = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hurricaneShelterSchool === 'No'} label="No" onChange={(e) => { studentReunificationData.hurricaneShelterSchool = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
                  
            <div className="flex-row">
              <div className='flex-col full-width'>
                <TextField label="Hours of Operation" type="text" maxLength="150" value={studentReunificationData.hoursOfOperation || ''} required onChange={(e) => { studentReunificationData.hoursOfOperation = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>		
              
            </div>

            <div className="flex-row">
              <div className='flex-col full-width'>
                <TextField label="Approx. # of Students" type="number" value={studentReunificationData.numberOfStudents || ''} required onChange={(e) => { studentReunificationData.numberOfStudents = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
              
            </div>	
            
            <div className="flex-row">
              <div className='flex-col full-width'>
                <TextField label="Approx. # of Staff" type="number" value={studentReunificationData.numberOfStaff || ''} required onChange={(e) => { studentReunificationData.numberOfStaff = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>	
            
            <div className="flex-row">
              <div className='flex-col full-width'>
                <TextField label="Bell Schedule" type="text" maxLength="150" value={studentReunificationData.bellSchedule || ''} required onChange={(e) => { studentReunificationData.bellSchedule = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>		 
            </div>

           <h2>Addtional Comments</h2>
 <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
              <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
              </div>
            </div>
          </form>
        </Paper>
      : null }
    </div>
  )
}