  export default class safetyEventService {
    getSafetyEvents(institutionBuildingId) {
        return fetch(`/api/safetyEvent/getSafetyEvents?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
        .then(response => { 
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json(); 
        })
        .catch( (error) => {
        console.error(error);
        throw Error(error);
        })
    }

    getSafetyEventAttendees(safetyEventId) {
        return fetch(`/api/safetyEvent/getSafetyEventAttendees?safetyEventId=${safetyEventId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
        .then(response => { 
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json(); 
        })
        .catch( (error) => {
        console.error(error);
        throw Error(error);
        })
    }
    

    saveSafetyEvent(safetyEvent) {
        return fetch('/api/safetyEvent/saveSafetyEvent', 
        { method: 'POST', 
            mode: 'cors', 
            credentials: 'include',
            body: JSON.stringify(safetyEvent),
            json: true,
            headers: {'Accept': 'application/json','Content-Type': 'application/json'}
        }
        )
        .then(response => { 
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.ok; 
        })
        .catch( (error) => {
        console.error(error);
        throw Error(error);
        })
    }

    deleteSafetyEvent(safetyEvent) {
        return fetch('/api/safetyEvent/deleteSafetyEvent', 
        { method: 'POST', 
            mode: 'cors', 
            credentials: 'include',
            body: JSON.stringify(safetyEvent),
            json: true,
            headers: {'Accept': 'application/json','Content-Type': 'application/json'}
        }
        )
        .then(response => { 
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.ok; 
        })
        .catch( (error) => {
        console.error(error);
        throw Error(error);
        })
    }
}