import React, { useReducer, useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useDebouncedCallback } from 'use-debounce';
import { Paper, Button, Icon, TextField, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import moment from 'moment'
import Photo from './Photo'
import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 2

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [loading, setLoading] = useState(true)
  const [siteVisit, setSiteVisit] = useState()
  const [siteVisitData, setSiteVisitData] = useReducer((state, newState) => ({ ...state, ...newState }),{});
  const [actionPlanOnly, setActionPlanOnly] = useState()

  useEffect( async () => {
    window.scroll(0,0)
    try {
      //get Owner
      let exteriorSubModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'siteVisit', 1)
      if (exteriorSubModuleData && exteriorSubModuleData[0]) {
        exteriorSubModuleData = JSON.parse(exteriorSubModuleData[0].data)
        setActionPlanOnly(context.user.role !== 'admin' && exteriorSubModuleData.siteVisitByUserId && exteriorSubModuleData.siteVisitByUserId !== context.user.userId)
      }

      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'siteVisit', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData) {
        setSiteVisit(subModuleData)
        let thisSiteVisitData = JSON.parse(subModuleData.data)
        setSiteVisitData(thisSiteVisitData)
      }
      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveSiteVisit = async (event) =>{
    event.preventDefault();
    try {
      //if action plans keys exist, mark the action plan complete
      if(Object.keys(siteVisitData).some(k => k.includes('ActionPlan')))
        siteVisitData.actionPlanComplete = true;
      
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'siteVisit', subModules: [{ subModuleId: SUBMODULE_ID,  data: siteVisitData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/sitevisit/clps/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  const debouncedSave = useDebouncedCallback(
    async () => {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'siteVisit', subModules: [{ subModuleId: SUBMODULE_ID,  data: siteVisitData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Auto Save')
    },
    5000
  );
  useEffect( () => {
    if (!loading)
      debouncedSave()
  },[siteVisitData])

  return (
    <div className="siteVisit">
        <Paper className="paper">
            <form onSubmit={saveSiteVisit} method='post' encType="multipart/form-data">
            <div className="flex-row top-actions">
              { siteVisit ? <div className="flex-col">Last Updated: {moment(siteVisit.timeStamp).format('MM/DD/YYYY')} By {siteVisit.lastUpdatedByFirstName} {siteVisit.lastUpdatedByLastName}</div> : <div className="flex-col"/> }
              <div className="flex-col">
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
              </div>
            </div>
              <div className="flex-row">
              <span className="question">Were any classroom doors found unlocked/propped open while occupied by students during instruction time?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.classRoomDoor === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.classRoomDoor = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.classRoomDoor == "No"} type="radio" required onChange={(e) => { siteVisitData.classRoomDoor = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.classRoomDoor == "NA"} type="radio" required onChange={(e) => { siteVisitData.classRoomDoor = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.classRoomDoorComment || ''} onChange={(e) => { siteVisitData.classRoomDoorComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.classRoomDoorActionPlan || ''} onChange={(e) => { siteVisitData.classRoomDoorActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={7}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Are there features available that restrict visibility into classrooms?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.classRoomVisibility === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.classRoomVisibility = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.classRoomVisibility == "No"} type="radio" required onChange={(e) => { siteVisitData.classRoomVisibility = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.classRoomVisibility == "NA"} type="radio" required onChange={(e) => { siteVisitData.classRoomVisibility = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.classRoomVisibilityComment || ''} onChange={(e) => { siteVisitData.classRoomVisibilityComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.classRoomVisibilityActionPlan || ''} onChange={(e) => { siteVisitData.classRoomVisibilityActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={8}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Did classrooms have hard corners and were they free of debris and clutter?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.hardCorner === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.hardCorner = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.hardCorner == "No"} type="radio" required onChange={(e) => { siteVisitData.hardCorner = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.hardCorner == "NA"} type="radio" required onChange={(e) => { siteVisitData.hardCorner = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.hardCornerComment || ''} onChange={(e) => { siteVisitData.hardCornerComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.hardCornerActionPlan || ''} onChange={(e) => { siteVisitData.hardCornerActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={9}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Did classrooms have emergency procedures, evacuation routes readily posted and/or available?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.emergencyProceduresPosted === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.emergencyProceduresPosted = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.emergencyProceduresPosted == "No"} type="radio" required onChange={(e) => { siteVisitData.emergencyProceduresPosted = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.emergencyProceduresPosted == "NA"} type="radio" required onChange={(e) => { siteVisitData.emergencyProceduresPosted = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.emergencyProceduresPostedComment || ''} onChange={(e) => { siteVisitData.emergencyProceduresPostedComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.emergencyProceduresPostedActionPlan || ''} onChange={(e) => { siteVisitData.emergencyProceduresPostedActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={10}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Was their ample interior signage (Right to Search, Fortify FL, Way-finder, etc.?)</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.signage === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.signage = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.signage == "No"} type="radio" required onChange={(e) => { siteVisitData.signage = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.signage == "NA"} type="radio" required onChange={(e) => { siteVisitData.signage = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.signageComment || ''} onChange={(e) => { siteVisitData.signageComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.signageActionPlan || ''} onChange={(e) => { siteVisitData.signageActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={11}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Was the CCTV system reviewed? Were there any cameras not functioning?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.camera === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.camera = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.camera == "No"} type="radio" required onChange={(e) => { siteVisitData.camera = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.camera == "NA"} type="radio" required onChange={(e) => { siteVisitData.camera = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.cameraComment || ''} onChange={(e) => { siteVisitData.cameraComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.cameraActionPlan || ''} onChange={(e) => { siteVisitData.cameraActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={15}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Were there any outstanding facilities/help tickets pending?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.ticketsPending === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.ticketsPending = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.ticketsPending == "No"} type="radio" required onChange={(e) => { siteVisitData.ticketsPending = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.ticketsPending == "NA"} type="radio" required onChange={(e) => { siteVisitData.ticketsPending = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.ticketsPendingComment || ''} onChange={(e) => { siteVisitData.ticketsPendingComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.ticketsPendingActionPlan || ''} onChange={(e) => { siteVisitData.ticketsPendingActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={16}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Any other interior safety & security items that warrant attention?</span>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 4000}} value={siteVisitData.otherComment || ''} onChange={(e) => { siteVisitData.otherComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 4000}} value={siteVisitData.otherActionPlan || ''} onChange={(e) => { siteVisitData.otherActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={17}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>


            <div className="flex-row">
              <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
            </div>
          </form>
        </Paper>

    </div>
  )
}