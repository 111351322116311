import React, { useReducer, useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useDebouncedCallback } from 'use-debounce';
import { Paper, Button, Icon, TextField,FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import moment from 'moment'
import Photo from './Photo'
import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 3

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [loading, setLoading] = useState(true)
  const [siteVisit, setSiteVisit] = useState()
  const [siteVisitData, setSiteVisitData] = useReducer((state, newState) => ({ ...state, ...newState }),{});
  const [actionPlanOnly, setActionPlanOnly] = useState()

  useEffect( async () => {
    window.scroll(0,0)
    try {
      //get Owner
      let exteriorSubModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'siteVisit', 1)
      if (exteriorSubModuleData && exteriorSubModuleData[0]) {
        exteriorSubModuleData = JSON.parse(exteriorSubModuleData[0].data)
        setActionPlanOnly(context.user.role !== 'admin' && exteriorSubModuleData.siteVisitByUserId && exteriorSubModuleData.siteVisitByUserId !== context.user.userId)
      }

      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'siteVisit', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData) {
        setSiteVisit(subModuleData)
        let thisSiteVisitData = JSON.parse(subModuleData.data)
        setSiteVisitData(thisSiteVisitData)
      }
      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveSiteVisit = async (event) =>{
    event.preventDefault();
    try {
      //if action plans keys exist, mark the action plan complete
      if(Object.keys(siteVisitData).some(k => k.includes('ActionPlan')))
        siteVisitData.actionPlanComplete = true;
      
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'siteVisit', subModules: [{ subModuleId: SUBMODULE_ID,  data: siteVisitData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/sitevisit/other/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  const debouncedSave = useDebouncedCallback(
    async () => {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'siteVisit', subModules: [{ subModuleId: SUBMODULE_ID,  data: siteVisitData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Auto Save')
    },
    5000 //5 seconds
  );
  useEffect( () => {
    if (!loading)
      debouncedSave()
  },[siteVisitData])

  return (
    <div className="siteVisit">
        <Paper className="paper">
            <form onSubmit={saveSiteVisit} method='post' encType="multipart/form-data">

            <div className="flex-row top-actions">
              { siteVisit ? <div className="flex-col">Last Updated: {moment(siteVisit.timeStamp).format('MM/DD/YYYY')} By {siteVisit.lastUpdatedByFirstName} {siteVisit.lastUpdatedByLastName}</div> : <div className="flex-col"/> }
              <div className="flex-col">
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
              </div>
            </div>

              <div className="flex-row">
              <span className="question">Were the school’s training modules at 100% completion?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.trainingCompletion === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.trainingCompletion = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.trainingCompletion == "No"} type="radio" required onChange={(e) => { siteVisitData.trainingCompletion = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.trainingCompletion == "NA"} type="radio" required onChange={(e) => { siteVisitData.trainingCompletion = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.trainingCompletionComment || ''} onChange={(e) => { siteVisitData.trainingCompletionComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.trainingCompletionActionPlan || ''} onChange={(e) => { siteVisitData.trainingCompletionActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={18}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Was the EMP updated to include all new personnel?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.planPersonelUpdated === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.planPersonelUpdated = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.planPersonelUpdated == "No"} type="radio" required onChange={(e) => { siteVisitData.planPersonelUpdated = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.planPersonelUpdated == "NA"} type="radio" required onChange={(e) => { siteVisitData.planPersonelUpdated = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.planPersonelUpdatedComment || ''} onChange={(e) => { siteVisitData.planPersonelUpdatedComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.planPersonelUpdatedActionPlan || ''} onChange={(e) => { siteVisitData.planPersonelUpdatedActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={19}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Was the EMP provided to all staff and a hard copy kept in the school’s go bag?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.planProvided === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.planProvided = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.planProvided == "No"} type="radio" required onChange={(e) => { siteVisitData.planProvided = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.planProvided == "NA"} type="radio" required onChange={(e) => { siteVisitData.planProvided = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.planProvidedComment || ''} onChange={(e) => { siteVisitData.planProvidedComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.planProvidedActionPlan || ''} onChange={(e) => { siteVisitData.planProvidedActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={20}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

              <div className="section flex-row">
              <span className="question">Was the reunification plan completed and updated?</span>
                <div className='flex-row'>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                       <FormControlLabel disabled={actionPlanOnly}  label="Yes" value="Yes" control={<Radio required />} checked={siteVisitData.reunificationUpdated === 'Yes'}  type="radio" required onChange={(e) => { siteVisitData.reunificationUpdated = e.target.value; setSiteVisitData(siteVisitData)}}/>
                       <FormControlLabel disabled={actionPlanOnly}  label="No" value="No" control={<Radio required />} checked={siteVisitData.reunificationUpdated == "No"} type="radio" required onChange={(e) => { siteVisitData.reunificationUpdated = e.target.value; setSiteVisitData(siteVisitData)}} />
                       <FormControlLabel disabled={actionPlanOnly}  label="NA" value="NA" control={<Radio required />} checked={siteVisitData.reunificationUpdated == "NA"} type="radio" required onChange={(e) => { siteVisitData.reunificationUpdated = e.target.value; setSiteVisitData(siteVisitData)}} />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex-row">
                  <TextField disabled={actionPlanOnly}  label="Comments"  type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.reunificationUpdatedComment || ''} onChange={(e) => { siteVisitData.reunificationUpdatedComment = e.target.value; setSiteVisitData(siteVisitData)}}/>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <TextField label="Action Plan" type="text" multiline={true} inputProps={{ maxLength: 1000}} value={siteVisitData.reunificationUpdatedActionPlan || ''} onChange={(e) => { siteVisitData.reunificationUpdatedActionPlan = e.target.value; setSiteVisitData(siteVisitData)}}/>
                    </div>
                </div>
                <div className="flex-row">
                    <div className='full-width'>
                      <Photo subModuleId={21}disableDelete={actionPlanOnly}></Photo>
                    </div>
                </div>
              </div>

            <div className="flex-row">
              <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
            </div>
          </form>
        </Paper>
    </div>
  )
}