import React from 'react'
import AppContext from '../context/app'

import emergencyManagementService from '../services/emergencyManagement';
import { TextField } from '@material-ui/core';


class templateModuleNarrative extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { subModuleData: { data: {} } }
    this.emergencyManagementService = new emergencyManagementService();
    this.handleChange = this.handleChange.bind(this);
    this.updateModuleProp = this.updateModuleProp.bind(this);
}

updateModuleProp(changeObject){
  let activeEmergencyManagementPlanModule = this.context.activeEmergencyManagementPlanModule ? this.context.activeEmergencyManagementPlanModule.slice() : []
  let activeEmergencyManagementPlanSubModule = activeEmergencyManagementPlanModule.find( (subModule) => { return subModule.subModuleId === this.props.configItem.subModuleId } )
  if (activeEmergencyManagementPlanSubModule)        
      activeEmergencyManagementPlanSubModule.data = changeObject.data
  else
      activeEmergencyManagementPlanModule.push({ subModuleId: this.props.configItem.subModuleId, data: changeObject.data } )
  this.context.updateActiveEmergencyManagementPlanModule(activeEmergencyManagementPlanModule)
}

  componentWillMount () {
    let configItem = this.props.configItem;

    //load up what data
    Promise.all([
        this.emergencyManagementService.getSubModuleData(this.props.configItem.institutionBuildingId, this.props.configItem.moduleName, this.props.configItem.subModuleId),
        this.emergencyManagementService.getSubModuleDefaultContent(configItem.beforeFile,this.props.configItem.institutionGroupId),
        this.emergencyManagementService.getSubModuleDefaultContent(configItem.afterFile,this.props.configItem.institutionGroupId)
    ])
    .then( (subModuleDataResult) => {
        let subModuleData = subModuleDataResult[0][0] || { data: '{}' }
        subModuleData.beforeContent = subModuleDataResult[1]
        subModuleData.afterContent = subModuleDataResult[2]
        subModuleData.data = JSON.parse(subModuleData.data)
        this.setState({ subModuleData })
        this.updateModuleProp(subModuleData)
    })
    .catch( (error) => {
        console.log(error);
    })
    
  }

  handleChange(event) {
    let changeObject = this.state.subModuleData
    changeObject.data[event.target.name] = event.target.value;    
    this.setState({ subModuleData: changeObject });

    this.updateModuleProp(changeObject)
  }

  render () {
    return (
      <div className='templateModuleNarrative'>
        <div>
            <span dangerouslySetInnerHTML={ {__html: this.state.subModuleData.beforeContent } } ></span>
            <span>
              <TextField type="text" className="input" name={this.props.configItem.updateProp} value={this.state.subModuleData.data[this.props.configItem.updateProp]} onChange={this.handleChange} />
            </span>
            <span dangerouslySetInnerHTML={ {__html: this.state.subModuleData.afterContent } } ></span>
        </div>
      </div>
    )
  }
}

export default templateModuleNarrative
