var fileDownload = require('js-file-download');

export default class emergencyManagementService {
  getAllEmergencyManagementSubModules(institutionBuildingId) {
    return fetch(`/api/emergencyManagement/getAllEmergencyManagementSubModules?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  getSubModuleData(institutionBuildingId, moduleName, subModuleId) {
    return fetch(`/api/emergencyManagement/getSubModuleData?institutionBuildingId=${institutionBuildingId}&moduleName=${moduleName}&subModuleId=${subModuleId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getSubModuleDefaultContent(content, institutionGroupId) {
    return fetch(`/api/emergencyManagement/getSubModuleDefaultContent?content=${content}&institutionGroupId=${institutionGroupId || ''}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getAudit(institutionBuildingId) {
    return fetch(`/api/emergencyManagement/getAudit?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  saveAudit(audit) {
    return fetch('/api/emergencyManagement/saveAudit', 
    { method: 'POST', 
      mode: 'cors', 
      credentials: 'include',
      body: JSON.stringify(audit),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }


  getModuleConfig(modulePage) {
    return fetch(`/api/emergencyManagement/getModuleConfig?modulePage=${modulePage}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getEmergencyManagementPdf(institutionBuildingId) {
    return fetch(`/api/emergencyManagement/getEmergencyManagementPdf?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getSubPolicies(institutionBuildingId) {
    return fetch(`/api/emergencyManagement/getSubPolicies?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  saveSubPolicy(policy) {
    return fetch('/api/emergencyManagement/saveSubPolicy', 
    { method: 'POST', 
      mode: 'cors', 
      credentials: 'include',
      body: JSON.stringify(policy),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getPolicy(institutionBuildingId, policyId) {
    return fetch(`/api/emergencyManagement/getPolicy?institutionBuildingId=${institutionBuildingId}&policyId=${policyId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  savePolicy(policy) {
    return fetch('/api/emergencyManagement/savePolicy', 
    { method: 'POST', 
      mode: 'cors', 
      credentials: 'include',
      body: JSON.stringify(policy),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }



  downloadPdf(institutionBuildingId, pdfName){
    window.open(`/api/emergencyManagement/downloadPdf?institutionBuildingId=${institutionBuildingId}&pdf=${pdfName}`)
  }

  download(institutionBuildingId, includeSections, documentFormat){
    window.open(`/api/emergencyManagement/download?institutionBuildingId=${institutionBuildingId}&${includeSections.map( o => `includeSections=${o}`).join('&')}&documentFormat=${documentFormat}`)
  }


  downloadAsset(emergencyManagementAssetId, fileName){
    return fetch(`/api/emergencyManagement/downloadAsset?assetId=${emergencyManagementAssetId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => response.blob())
    .then(blob => { 
      fileDownload(blob, fileName);
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  downloadManagedExcel() {
    window.location = '/api/emergencyManagement/downloadManagedExcel'
  }

  notify(institutionBuildingId, section){
    return fetch(`/api/emergencyManagement/notify?institutionBuildingId=${institutionBuildingId}&section=${section}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  create(emergencyManagement) {
    return fetch('/api/emergencyManagement/createEmergencyManagement', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(emergencyManagement),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => response.json())
    .catch( (err) => {
      console.log(err)
      throw new Error(err)
    })

  }

  update(emergencyManagementModule) {
    return fetch('/api/emergencyManagement/saveModules', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(emergencyManagementModule),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  archive(institutionBuildingId){
    return fetch('/api/emergencyManagement/archive', 
    { method: 'POST', 
      mode: 'cors', 
      credentials: 'include',
      body: JSON.stringify({institutionBuildingId}),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    }
  )
  .then(response => { 
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.ok; 
  })
  .catch( (error) => {
    console.error(error);
    throw Error(error);
  })
  }

  getAssetNames(institutionBuildingId, type, subModuleId){
    return fetch(`/api/emergencyManagement/getAssetNames?institutionBuildingId=${institutionBuildingId}&type=${type}&subModuleId=${subModuleId || ''}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  uploadFile(institutionBuildingId, type, file, notes, subModuleId, subType){
    return fetch(`/api/emergencyManagement/uploadFile?institutionBuildingId=${institutionBuildingId}&type=${type}&subModuleId=${subModuleId || ''}&notes=${notes || ''}`, {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      body: file,
    }).then((response) => {
      if (!response.ok)
        throw Error(response.statusText);
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  deleteAsset(assetId){
    return fetch('/api/emergencyManagement/deleteAsset', {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      json: true,
      body: JSON.stringify({ assetId }),
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    }).then((response) => {
      if (!response.ok)
          throw Error(response.statusText);
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

}