import React from 'react';
import AppContext from '../../context/app'

import { createBrowserHistory } from 'history';
import emergencyManagementService from '../../services/emergencyManagement';
import institutionService from '../../services/institution';
import TemplateModuleRow from '../../components/TemplateModuleRow';
import TemplateModuleContent from '../../components/TemplateModuleContent';
import TemplateModuleNarrative from '../../components/TemplateModuleNarrative';

class templateModule extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { 
      moduleConfig: [],
      modules: [
        { name: 'Emergency Plan Overview', page: 'emergencyPlanOverviewModule' },
        { name: 'Functional Annexes', page: 'functionalAnnexesModule' },
        { name: 'Threat Specific Annexes', page: 'threatSpecificAnnexesModule' },
        { name: 'School Safety Teams', page: 'schoolSafetyTeamsModule' },  
        { name: 'Organizational Incident Command System', page: 'organizationalIncidentCommandSystemModule' },
        { name: 'Safety Drills and Training', page: 'safetyEventModule', noTemplate: true },
        { name: 'Images / Floor Plans / Documents', page: 'documentsModule', noTemplate: true  },
        { name: 'Certificates', page: 'documentsModule', noTemplate: true  }
      ]
    };
    this.institutionService = new institutionService()
    this.emergencyManagementService = new emergencyManagementService();    
    this.handleSave = this.handleSave.bind(this);
    this.history = createBrowserHistory();
  }

  async bindPage(props){   
    // reset
    this.setState({ moduleConfig: [] }) 

    //load if we have a saved one
    let module = this.state.modules.find( (module) => { return module.page === props.match.params.modulePage })
    this.setState({ module })

    try {
      let [building, moduleConfig] = await Promise.all([
        this.institutionService.getBuilding(props.match.params.institutionBuildingId),
        this.emergencyManagementService.getModuleConfig(props.match.params.modulePage)
      ])
      moduleConfig.forEach( (subModuleConfig) => {
        subModuleConfig.institutionBuildingId = props.match.params.institutionBuildingId
        subModuleConfig.moduleName = props.match.params.modulePage  
        subModuleConfig.institutionGroupId = building.institutionGroupId
      })
      this.setState({ moduleConfig })
    }
    catch(err){
      console.log(err)
      this.context.toastError('Loading')
    }
  }

  componentWillReceiveProps(nextProps, nextContext){
    if (this.props.match.params.modulePage !== nextProps.match.params.modulePage)
      this.bindPage(nextProps)
  }

  componentWillMount () {
    window.scroll(0,0)

    this.bindPage(this.props)
  }

  render () {
    return (
      <div id="templateModule">
            <div>
              <h1 className="sub-title">{this.state.module.name}</h1>
              
              <form onSubmit={this.handleSave} method='post' encType="multipart/form-data">
              {
                this.state.moduleConfig.map( (configItem, index) => {
                  return (  
                    <div key={index}>
                      { configItem.type === 'row' ?
                        <TemplateModuleRow {...this.props} configItem={configItem}></TemplateModuleRow>
                      : null }

                      { configItem.type === 'content' ?
                        <TemplateModuleContent {...this.props} configItem={configItem}></TemplateModuleContent>
                      : null }

                      { configItem.type === 'narrative' ?
                        <TemplateModuleNarrative {...this.props} configItem={configItem}></TemplateModuleNarrative>
                      : null }


                    </div>
                  );
                })
              }


              <div>
                <input type="submit" className='btn-next btn-left' value="Save" />
              </div>
               

              </form>
            </div>
        </div>
    )
  }

  //save the answers
  async handleSave(event){
    event.preventDefault();

    try {
      let emergencyManagementPlanModule = { institutionBuildingId: this.props.match.params.institutionBuildingId, moduleName: this.state.module.page, subModules: this.context.activeEmergencyManagementPlanModule };
      await this.emergencyManagementService.update(emergencyManagementPlanModule)
      let nextModule = this.state.modules[this.state.modules.findIndex( (module) => {  return module.page === this.state.module.page })+1]

      this.context.updateActiveModule(nextModule.name)      
      this.context.updateActiveEmergencyManagementPlanModule(null)

      this.props.history.push({pathname: `/emergencyManagement/${ nextModule.noTemplate ? '' : 'templateModule/'}${nextModule.page}/${+this.props.match.params.institutionBuildingId || ''}` });
      window.scroll(0,0)
      this.context.toastSuccess('Saved')
    }
    catch(err){
      console.log(err)
      this.context.toastError('Could not save')
    }
  }

}

export default templateModule
