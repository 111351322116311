import React from 'react';
import AppContext from '../../context/app'

import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import userService from '../../services/user';
import emergencyManagementService from '../../services/emergencyManagement';
import institutionService from '../../services/institution';
import { Button, Icon } from '@material-ui/core';

class PoliciesList extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { policies: [] }
    this.history = createBrowserHistory();
    this.userService = new userService();
    this.institutionService = new institutionService();
    this.emergencyManagementService = new emergencyManagementService();    

  }

  async componentDidMount () {
    window.scroll(0,0)
    try {
      let building = await this.institutionService.getBuilding(this.props.match.params.institutionBuildingId)
      let policies = await this.emergencyManagementService.getSubPolicies(this.props.match.params.institutionBuildingId, building.institutionGroupId)
      this.setState({ policies })
    }
    catch(err){
      console.log(err)
      this.context.toastError('Loading')
    }
  }

  render () {
    return (
      <div id='policies'>
        { this.context.user ?
            <div>
               <h2>Policies</h2>
                {
                    this.state.policies.map( (policy) => {
                        return (
                        <Link className="policy-link" key={policy.policyId} to={`/emergencyManagement/policy/${this.props.match.params.institutionBuildingId}/${policy.policyId}`}>
                            <div className="flex-row policy" >
                                <span className="flex-col policyTitle">
                                  { policy.subPolicies.filter(p => p.isAcknowledged).length === policy.subPolicies.length ?
                                      <span className="complete-check-container"><i className='fa fa-check-circle'></i></span>
                                  : 
                                    <span className="complete-check-container"><span className="check-placeholder"></span></span>
                                  }
                                  <span>{policy.title}</span> 
                                </span>

                                <span className="flex-col">
                                  <Button variant="outlined"><Icon className="fa fa-edit" />Open</Button>
                                </span>
                            </div>
                        </Link>
                        );
                    })
                }
            </div>
        :
            null
        }
      </div>
    )
  } 
}

export default PoliciesList
