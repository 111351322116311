import React from 'react';
import AppContext from '../context/app'

import emergencyManagementService from '../services/emergencyManagement';

import ContentEditable from 'react-contenteditable'

class templateModuleContent extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = { subModuleData: { } }
    this.emergencyManagementService = new emergencyManagementService();    
    this.handleChange = this.handleChange.bind(this);
    this.updateModuleProp = this.updateModuleProp.bind(this);
  }

  updateModuleProp(content){
    let activeEmergencyManagementPlanModule = this.context.activeEmergencyManagementPlanModule ? this.context.activeEmergencyManagementPlanModule.slice() : []
    let activeEmergencyManagementPlanSubModule = activeEmergencyManagementPlanModule.find( (subModule) => { return subModule.subModuleId === this.props.configItem.subModuleId } )
    if (activeEmergencyManagementPlanSubModule){
      activeEmergencyManagementPlanSubModule.data = [{}]
      activeEmergencyManagementPlanSubModule.content = content
    }
    else
        activeEmergencyManagementPlanModule.push({ subModuleId: this.props.configItem.subModuleId, content } )
    this.context.updateActiveEmergencyManagementPlanModule(activeEmergencyManagementPlanModule)
  }

  componentWillMount () {
    let configItem = this.props.configItem;
    
    //load up what data
    this.emergencyManagementService.getSubModuleData(this.props.configItem.institutionBuildingId, this.props.configItem.moduleName, this.props.configItem.subModuleId)
    .then( (subModuleData) => {      
      subModuleData = subModuleData[0]
      if (subModuleData && subModuleData.content){
          this.setState({ subModuleData })
          this.updateModuleProp(subModuleData.content)
          return;
      }

      //if no data load up default
      this.emergencyManagementService.getSubModuleDefaultContent(configItem.contentFile,this.props.configItem.institutionGroupId)
      .then( (subModuleContent) => {
          this.setState({ subModuleData: { content: subModuleContent }})
          this.updateModuleProp(subModuleContent)
        })
    })
    .catch( (error) => {
        console.log(error);
    })
  }

  render () {
    return (
        <div className="templateModuleContent">
                { this.state.subModuleData.content ?
                  <ContentEditable
                    html={this.state.subModuleData.content}
                    onChange={this.handleChange}
                  />
                : null }
        </div>
    )
  }

    handleChange(event){
        this.setState({ subModuleData: { content: event.target.value}});

        this.updateModuleProp(event.target.value)

    
    
    }

}

export default templateModuleContent
