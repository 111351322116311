import React, { useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField } from '@mui/material';

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 7

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { educationAdministrationResponsiblities: [
      { responsibility:  'Call 911' } ,
      { responsibility:  'Notify District office / draft communication for parents – what happened, where going, bring ID' } ,
      { responsibility:  'Bring emergency cards and laptop to evacuation site.' } ,
      { responsibility:  'Make automated call home after approved by Communications ' } ,
      { responsibility: 'Work with Transportation Team and oversee accountability of students/staff at impacted site.' } ,
      { responsibility: 'Staging areas and procedures for parents, students, and media.' } ,
      { responsibility:  'Take emergency cards to ambulances and determine destination if necessary' } ,
      { responsibility:  'Assign counselors and teams to go to hospitals to meet families' } ,
      { responsibility:  'Go on lead bus/lead walker Bring master roster to evacuation site and ensure students are situated' } ,
      { responsibility:  'Get a team together and situate the Individuals who will distribute Evacuation site information to Parents arriving at evacuation site' } ,
      { responsibility:  'Make sure everyone has evacuated campus, notify Lead, upon arrival at new site make sure assigned staff member is distributing evacuation site information to arriving parents, continue to monitor new site for safety and assist admin and staff.' }
    ], 
    reunificationAssignments: [
      { title: 'Incident Commander' },
      { title: 'Operations Chief' },
      { title: 'Public Information Officer' },
      { title: 'Safety Officer' },
      { title: 'Liasion Officer' },
      { title: 'Exterior Director' },
      { title: 'Greeter' },
      { title: 'Checker' },
      { title: 'Reunifier' },
      { title: 'Interior Director' },
      { title: 'Class Leader (Kid Wrangler) /Entertainment' },
      { title: 'Release/Accountant' },
      { title: 'Crisis Councelors' },
      { title: 'Planning/Scribe' },
      { title: 'Logistics' }
    ], 
    emergencyPreparednessChecklist: [
      { task: 'Ensure that your site information is complete and up-to-date' },
      { task: 'Identify members of your school safety team' },
      { task: 'Ensure that your school safety team have completed the required Training' },
      { task: 'Assign roles to your staff' },
      { task: 'Identify Incident Command Post locations' },
      { task: 'Identify staff with special skills for help during emergency situations' },
      { task: 'Ensure all classrooms have up-to-date evacuation maps' },
      { task: 'Ensure classroom go-kits and emergency folders are assembled and up-to-date' },
      { task: 'Schedule and complete annual safety training for staff and students' },
      { task: 'Review and revise school emergency communications plans' },
      { task: 'Designate on-site/off-site evacuation locations' },
      { task: 'Identify and contact off site emergency evacuation locations' },
      { task: 'Identify individuals needing evacuation assistance and develop Individualized Evacuation Plans (Monthly)' },
      { task: 'School specific reunification plan for both on-site and off-site reunification' }
    ]
    });


  useEffect( async () => {
    window.scroll(0,0)
    try {
      let subModuleData = await new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID)
      subModuleData = subModuleData[0]
      if (subModuleData)
        setStudentReunificationData(JSON.parse(subModuleData.data))
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/threat/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
              <div className="section">
                    Circumstances may occur at the school that requires parents to pick up there students in a formalized, controlled release. The Standard Reunification Method (SRM) is a protocol that makes this type of release more predictable and less chaotic for all involved. The SRM should be reviewed annually.	
              </div>

              <h2>Purpose</h2>
              <div className="section">
                    The purpose of the Standard Reunification Method (SRM) is to establish a procedure and set of options for large scale events that can be utilized by schools personnel in handling student to parent/guardian reunification from the school or from an off-site location.  Implementation considerations by school staff or Incident Commander have also been provided when an “off-site” evacuation has been ordered.	
              </div>

              <div className="section">
                In smaller scale events, when the full Controlled Release procedures are deemed unnecessary, schools may find it helpful to modify standard dismissal procedures in order to maximize student and staff safety. Possible modifications to standard dismissal procedures include:	
                <ul>
                  <li>Time of dismissal (late or early).</li>
                  <li>Means of transportation (change in allowable means for safety reasons).</li>
                  <li>Availability of exits (use of some exterior doors or driveways rather than others).</li>
                  <li>Partial dismissal (dismissing different student groups at different times).</li>
                </ul>
              </div>

              <h2>Definitions</h2>
              <div className="section">
                <b>Reunification/Controlled Release:</b><br/> 
                Procedure implemented by school districts for student/parent/guardian reunification if the school is evacuated or closed due to a hazardous situation (i .e ., hazardous materials transportation accident, fire, natural gas leak, flooding, earthquake, school violence, bomb threat, terrorist attack or other local hazard) .  This process may occur on site or off site.	
              </div>

              <div className="section">
                <b>Modified Dismissal:</b><br/>
                Releasing students from the school in a way that differs from standard procedures in one or more ways, but that does not require the level of attention to student/parent/ guardian reunification as a full Controlled Release.	
              </div>

              <div className="section">
                <b>Reunification Site Director:</b><br/>
                Person responsible for implementing the procedure for the controlled release of the student to parent/guardian at the designated site location.	
              </div>

              <div className="section">
                Preparedness procedures for reunification:	
                <ol>
                  <li>Student emergency information rosters should be updated at least twice a year.</li>
                  <li>Each class will have its current roster stored in every classroom in an area easily identified by both teachers and substitutes.</li>
                </ol>
              </div>


              <div className="section">
                <b>Reunification Procedures During a Crisis</b><br/>
                <div>
                  In an emergency, schools must establish a safe area for parents/guardians to go to pick up students. This area must be away from the immediate crisis and the student’s assembly area. In a typical release the following steps will be followed:<br/>
                  * Please note if the event is large enough a district response team may be dispatched to the school location or the regional Reunification Site if necessary.  	
                </div>
                <ol>
                  <li>Schools will implement notification procedures to notify parents/guardians of the emergency.	</li>
                  <li>Parent/Guardian will report to the Parent/Guardian check-in area and give the name of the child.	</li>
                  <li>Picture I.D. Will normally be required by the person in charge to ensure the person requesting the child is a match to the name on the emergency release card. The schools may choose to rely on students to confirm the identity of the person coming to pick them up and ask students brief follow-up information rather than require identification to be presented.	</li>
                  <li>A runner will go to the student assembly area or classroom and get the child requested by the parent/guardian or adult.  The runner will escort the student back to the pick-up area. Schools may use an alternate method of reunifying the student to the parent/ guardian.  Some methods include:	</li>
                  <ol type="a">
                    <li>Grouping students either alphabetically by last name or by grade level and locating parents/ guardians to the corresponding area to pick up the child.	</li>
                    <li>Utilizing a line of sight approach where the student would be released from the assembly area and go to the location of the parent/guardian, while designated personnel watch them to ensure the student arrives safely to the destination.	</li>
                  </ol>
                  <li>Parent/Guardian will be asked to fill out a reunification card.  The date and time will also be indicated on the reunification card.	</li>
                  <ol type="a">
                    <li>The reunification card can be divided into sections and disseminated to district personnel at various checkpoints to ensure the child is being picked up by the appropriate person.	</li>
                  </ol>
                  <li>If the child is in the first aid/medical treatment area, the parent/guardian will be directed to that area for reunification with the child.</li>
                  <li>Counselors, when available, will be located close to the first aid area in the event counseling services are needed.	</li>
                </ol>
              </div>

              <div className="section">
                <b>Other Considerations for Reunification</b>
                <ul>
                  <li>Shortly after the incident, the media will have a presence on the campus.  The PIO, part of the Spuerintendants Staff,  will deal with the media. However, it is important that parents/guardians be sheltered from media representatives.	</li>
                  <li>Translators may need to be available at various checkpoints.	</li>
                  <li>Ensure students and staff who have special needs or accommodations are assisted.  Request help if needed.	</li>
                  <li>Depending on the time of day, nutrition services may need to be involved to provide a meal and/or snacks to staff and students for the duration of the reunification process. 	</li>
                  <li>Some parents/guardians will refuse to cooperate with the student/parent/guardian controlled release process.  This situation can be diminished, to some degree, if parents/ guardians are informed about the Controlled Release procedures before the disaster or emergency occurs.  Parents/Guardians should be reminded that the safety of the students is the utmost priority in the school.  It is a good idea to include this material in the student handbook distributed at the beginning of the school year.	</li>
                  <li>Parents/Guardians may be emotional when arriving at the school.  Plan to make counseling available to deal with these issues ensuring to consider timing, space, availability of resources, etc.	</li>
                  <li>Other resources to utilize if necessary; Sheriff's Office, victim advocates, district administrative staff, local/county emergency management personnel.	</li>
                </ul>
              </div>	

              <div className="section">
                <h3>Reunification Quicklist</h3>
                Establish Incident Command and Reunification Site Director:
                <ul>
                <li>Secure Site and establish a student assembly area for arriving students and staff away from waiting family members.</li>
                <li>Check identification of all non-uniformed personnel who arrive to assist. </li>
                <li>Establish a Parent/Guardian check-in area for parents/guardians to sign-in and to check identification.</li>
                <li>Establish a parent/student controlled release area where students will be escorted to meet the parent/guardian and sign out.</li>
                <li>Establish a victim assistance area and direct staff to escort parent/guardian of any injured, missing or deceased student to the area for staff to provide notification in private away from other parents/guardians.</li>
                <li>Establish a media staging area and notify the school media liaison of the location. </li>
                <li>Implement the controlled release area where students will be escorted to meet the parent/guardian and sign out. </li>
                <li>Establish a First aid and special needs area if needed.</li>
                <li>Coordinate this with District Nurses.</li>
                <li>Establish a victim assistance area and direct staff to escort parent/guardian of any injured, missing or deceased student to the area for staff to provide notification in private, away from other parents/guardians.</li>
                <li>Establish a media staging area (off school property) and notify the school media liaison of the location. </li>
                <li>Implement the controlled release of students.</li>
                </ul>
                ALL STAFF Course of Action:
                <ul>
                <li>Remain calm - Listen, follow instructions and do not panic.</li>
                <li>Teachers - Keep students together and take attendance:</li>
                <li>Identify students who are missing as well as students who may not be assigned to your class. </li>
                <li>Immediately report injured, missing, or additional students to school administrators.</li>
                <li>Deliver attendance list to the Reunification Site Director upon arrival. </li>
                <li>Ensure students and staff who have special needs or accommodations are assisted. Request help if needed.</li>
                <li>Follow the instructions of the Reunification Site Director upon arrival. If requested, provide assistance with staffing the site. </li>
                <li>Bilingual teachers/paraprofessionals will assist with the translation of information for those whom English is not their first language or preferred language of communication. </li>
                </ul>
              </div>

                <h2>Reunification Roles and Definitions</h2>
                <table>
                <tr><td>Incident Commander</td><td>"Coordinate Priorities, Objectives, Strategies and Tactics for an accountable, easy reunification of students with parents"</td></tr>
                <tr><td>Operations</td><td>	Manage Operational Staff</td></tr>
                <tr><td>Public Information Officer</td><td>	Communicating with Parents and Media- Coordinated use of mass calls, social media and text messaging </td></tr>
                <tr><td>Safety Officer</td><td>	Observe site and remedy safety concerns</td></tr>
                <tr><td>Liasion Officer</td><td>	Communicating with Fire, Medical and Law Enforcement</td></tr>
                <tr><td>Greeters</td><td>	Help coordinate the parent lines. Tell parents about the process. Help verify parents without ID</td></tr>
                <tr><td>Checkers</td><td>	Verify ID and possibly custody rights of parents or guardians. Direct parents to Reunification Area.</td></tr>
                <tr><td>Reunifier</td><td>	Take bottom of Reunification Card to Assembly Area, locate student and bring to Reunification Area. Ask student, “Are you okay going home with this person?”</td></tr>
                <tr><td>Class Leader</td><td>	Manage students by class</td></tr>
                <tr><td>Entertainment</td><td>	Assist teachers and staff in Assembly area/assist in keeping students entertained to ease the tension</td></tr>
                <tr><td>Release</td><td>	Releases the student to the parent and verifies all the paperwork was completed</td></tr>
                <tr><td>Scribe</td><td>	Assist the Incident Commander and documents the event</td></tr>
                <tr><td>Logistics and Set up</td><td>	Reports to the Incident Commander- sets up the reunification areas with signage</td></tr>
                </table>

                <div className="section">
                  <img src="/img/reunificationSetupGoals.png" ></img>
                </div>

                <h3>Evacuation Administrative Responsibilities</h3>
                <div>
                  { studentReunificationData.educationAdministrationResponsiblities.map( (educationAdministrationResponsiblity, index) => {
                      return (
                        <div key={index} className="flex-row row top-connected">
                            <div className='flex-col'>
                              <TextField label="Name" type="text" maxLength="45" value={educationAdministrationResponsiblity.name || ''} required onChange={(e) => { educationAdministrationResponsiblity.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                            </div>
                            <div className='flex-col data-col'>
                              { educationAdministrationResponsiblity.responsibility }
                            </div>
                        </div>
                      )
                    })}
                </div>

                <h2>Reunification Assignments</h2>
                <div>
                  { studentReunificationData.reunificationAssignments.map( (reunificationAssignment, index) => {
                      return (
                        <div key={index} className="flex-row row top-connected">
                            <div className='flex-col'>
                              <TextField label="Name" type="text" maxLength="45" value={reunificationAssignment.name || ''} required onChange={(e) => { reunificationAssignment.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                            </div>
                            <div className='flex-col data-col'>
                              { reunificationAssignment.title }
                            </div>
                        </div>
                      )
                    })}
                </div>

                <div className="flex-row">
                    <div className='flex-col full-width'>
                      Reunification Walking Site
                    </div>
                </div>
                <div className="flex-row row top-connected">
                  <div className='flex-col'>
                    <TextField label="Location" type="text" maxLength="45" value={studentReunificationData.reunificationWalkingSiteLocation || ''} required onChange={(e) => { studentReunificationData.reunificationWalkingSiteLocation = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                  <div className='flex-col'>
                    <TextField label="Address" type="text" maxLength="100" value={studentReunificationData.reunificationWalkingSiteAddress || ''} required onChange={(e) => { studentReunificationData.reunificationWalkingSiteAddress = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>	

                <div className="flex-row">
                  <div className='flex-col full-width'>   
                    Reunification Regional Site
                    </div>
                </div>
                <div className="flex-row row top-connected">
                  <div className='flex-col'>
                    <TextField label="Location" type="text" maxLength="45" value={studentReunificationData.reunificationRegionalSiteLocation || ''} required onChange={(e) => { studentReunificationData.reunificationRegionalSiteLocation = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                  <div className='flex-col'>
                    <TextField label="Address" type="text" maxLength="100" value={studentReunificationData.reunificationRegionalSiteAddress || ''} required onChange={(e) => { studentReunificationData.reunificationRegionalSiteAddress = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>	

                <div className="section">
                  To assist on locating Regional Site please click on https://dsbpc.maps.arcgis.com/apps/dashboards/3b9d00b80a754c359f5d9268a8e8ef3e	<br/>
                  Please have a school floorplan/map with identified areas for your school reunification.	<br/>
                  You will need a copy of the school rosters with Parent/Guardian information	
                </div>
               

                <h2>School Preparedness Go-Kit: This will replace your Crisis Box</h2>
                <div className="section">
                  Ensure Go-Kits and Emergency Folders are assembled and up to date. In order to provide all teachers and substitute teachers with the most current emergency information, all schools should have emergency folders in each classroom with class room documents and the School Kit should have larger supplies that include at a minimum-please note some items may not be needed to be stored in the kit but leave a sheet in the kit of items you may need to gather during a reunification:					
                  <ul>
                  <li>Class Rosters-not necessarily to be placed in the kit but each teacher should have their working copy.  Rosters are uploaded into Crisis Go.					</li>
                  <li>List all staff contact phone numbers-primarily cell numbers-School Go-Kit					</li>
                  <li>Reunification Cards- These will be kept in the District Go Kit-  If an on campus reunification is needed you can print the document from the listed website.  We will see about getting a supply for each campus: https://iloveuguys.org/download/srm-reunification-card/					</li>
                  <li>Evacuation procedures, routes, and locations- Each class should have these					</li>
                  <li>Site Threat Continuum- Each Classroom should have one displayed					</li>
                  <li>Significant Health Condition Lists- special needs, medications, etc.- This should be with the school nurse or clinic aide.  Please ensure this is gathered during a reunification. 					</li>
                  <li>School Go Kit emergency supplies:	</li>
                  <ul>				
                  <li>Small band-aids					</li>
                  <li>larger band-aids					</li>
                  <li>Small Supply of feminine products and toiletries					</li>
                  <li>non-latex gloves					</li>
                  <li>First Aid Guide - for major bleeding, respiratory distress, low blood sugar, seizure, fainting-glucose gel or glucose tabs to treat low blood sugar					</li>
                  </ul>
                  <li>School Roster					</li>
                  <li>Site Maps- surrounding areas					</li>
                  <li>Copy of the EOP					</li>
                  <li>Pen and Paper to include SHARPIE Markers		</li>
                  <ul>			
                  <li>Non-essentials - good to have:					</li>
                  <li>Whistle 					</li>
                  <li>CPR Shield					</li>
                  <li>Bottles of Water- I would not keep these in the kits					</li>
                  <li>Snacks (non-perishable)- I would not keep these in the kits	</li>
                  </ul>				
                  <li>At least 5 fluorescent  vests- if available					</li>
                  <li>Caution Tape- if available					</li>
                  <li>Suggestion- Current yearbook or picture proofs with students names if available					</li>
                  <li>If you need to reunify please grab the Red Trauma bag to bring with you	</li>
                  </ul>				
                </div>

                <h3>Yearly Emergency Preparedness Checklist</h3>
                <div>
                  { studentReunificationData.emergencyPreparednessChecklist.map( (emergencyPreparednessChecklistItem, index) => {
                      return (
                        <div key={index} className="flex-row row top-connected">
                            <div className='flex-col'>
                              <TextField InputLabelProps={{ shrink: true }} label="Last Complete" placeholder='' type="date" value={emergencyPreparednessChecklistItem.lastCompleteDate || ''} required onChange={(e) => { emergencyPreparednessChecklistItem.lastCompleteDate = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                            </div>
                            <div className='flex-col'>
                              <TextField label="Name" type="text" maxLength="45" value={emergencyPreparednessChecklistItem.name || ''} required onChange={(e) => { emergencyPreparednessChecklistItem.name = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                            </div>
                            <div className='flex-col data-col'>
                              { emergencyPreparednessChecklistItem.task }
                            </div>
                        </div>
                      )
                    })}
                </div>

                <div className="section">
                  <img className="big-img" src="/img/reunificationStagingSite.png" ></img>
                </div>

                <div className="section">
                  <img className="big-img" src="/img/reunificationSteps.png" ></img>
                </div>

                <div className="section">
                  <span>
                    <img src="/img/reunificationSignageStaging.png" ></img>
                  </span>
                  <span>
                    <img src="/img/reunificationEnforcementStaging.png" ></img>
                  </span>
                </div>

                <div className="section">
                  <span>
                    <img src="/img/reunificationMethodInteriorStaging.png" ></img>
                  </span>
                  <span>
                    <img src="/img/reunificationMethodFireEmsStaging.png" ></img>
                  </span>
                </div>

                <div className="section">
                  <img src="/img/reunificationMethodGlossary.png" ></img>
                </div>

           
                    
                <h2>Addtional Comments</h2>
                <div className="flex-row">
                    <div className='flex-col full-width'>
                      <div>
                        <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                      </div>
                    </div>
                </div>


            <div className="section">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
          </form>
        </Paper>
    </div>
  )
}