export default class institutionService {
  async getAll() {
    return fetch('/api/institution/getAll', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  async getAllBuildings() {
    return fetch('/api/institution/getAllBuildings', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  async get(institutionId) {
    return fetch(`/api/institution/get?institutionId=${institutionId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  async getBuilding(institutionBuildingId) {
    return fetch(`/api/institution/getBuilding?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  async getSystemsByBuildingId(institutionBuildingId) {
    return fetch(`/api/institution/getSystemsByBuildingId?institutionBuildingId=${institutionBuildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
  

}