import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper } from '@mui/material';
import FileViewer from '../../../components/FileViewer'

import emergencyManagementService from '../../../services/emergencyManagement'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import AppContext from '../../../context/app'

const FILETYPE = 'studentReunification'

export default function Component(props) {
  const context = useContext(AppContext)
  const { institutionBuildingId } = useParams()

  const [files, setFiles] = useState([])
  const [assetNames, setAssetNames] = useState([])
  const [fileViewerOpen, setFileViewerOpen] = useState(false)
  const [fileViewerAssetIndex, setFileViewerAssetIndex ] = useState()

  const bindAssets = async () => {
    let assetNames = await new emergencyManagementService().getAssetNames(institutionBuildingId, FILETYPE)
    const imageExt = ['.png','.gif','.jpg','.jpeg']
    let endsWithAny = (suffixes, string) => {
      return suffixes.some(function (suffix) {
            return string.toLowerCase().endsWith(suffix);
      });
    }
    assetNames.forEach( (a) => {
      a.isImage = endsWithAny(imageExt, a.fileName)
    })
    setAssetNames(assetNames)
  }

  useEffect( async () => {
    window.scroll(0,0)
    try {
      bindAssets()
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const handleUploadFile = async (files) => {
    if (!files.length)
      return

    try {
      async function uploadFiles() {
        for (const file of files) {
          let data = new FormData()
          data.append('file', file.file)
          await new emergencyManagementService().uploadFile(+institutionBuildingId, FILETYPE, data)
        }
      }

      await uploadFiles.bind(this)()

      //reload the images
      bindAssets()
      setFiles([])
      context.toastSuccess('File uploaded')
    }
    catch(err){
      console.log(err)
      context.toastError('File could not be uploaded')
      setFiles([])
    }
  }

  const handleDeleteImage = async (assetId) => {
    try {
      await new emergencyManagementService().deleteAsset(assetId )
      bindAssets()
      context.toastSuccess('File deleted')
    }
    catch(err){
      console.log(err)
      context.toastError('File could not be deleted')
    }

  }

  return (
    <>
      <FileViewer open={fileViewerOpen} onClose={e => setFileViewerOpen(false)} files={assetNames} index={fileViewerAssetIndex} institutionBuildingId={institutionBuildingId} fileType={FILETYPE} ></FileViewer>
      <div className="studentReunification">
        <Paper className="paper">
          <div className="flex-row">
           Note - Preview the photos in the Photos and Documents Section that are already there.  Photos do not have to be retaken each year if they are still current and applicable.  If photos were not taken well and you can replace them with better ones, feel free to do that and label them well.  Photos must be labeled as to what the photo shows.  DO NOT upload the photo file from the camera (such as 10357.jpg).  Do not place photos on a Word Document or use any kind of zip file.  Photos must be uploaded as individual labeled files.  On your Asset page for your campus on the FSSAT application please select the Photo/Documents section and upload any photo's by choosing the "+" in the upper right corner.  Log into the FSSAT program and choose the ASSET icon and locate your school.								
					</div>		
								
          <div className="flex-row">
            LIST OF PHOTOS TO ATTACH TO FSSAT - Below is a list of photos that are to be included in the Photos and Documents Section of FSSAT.  Photos must be uploaded as single jpeg files, not within a zip file and not posted in Word (or other similar) documents.  File names for each photo must be changed to indicate what each photo shows.  Your SRO and SSG have access to the Asset Section in the FSSAT to add these photo's								
          </div>
          
          <div className="flex-row">
            <ol>                 
                <li>Aerial Picture of Campus (Can be captured off of Google Earth, Google Maps, Bing Maps)								</li>
                <li>Front view of Campus								</li>
                <li>Campus main entrance								</li>
                <li>Each side view of and rear view of campus								</li>
                <li>Entry to main office								</li>
                <li>Interior of main office lobby  (view both ways - visitor view and staff view)								</li>
                <li>Inside pictures of cafeteria (end to end both ways).								</li>
                <li>Auditorium (from stage view and to stage view).  [If Applicable]								</li>
                <li>Gymnasium (end to end each way).   [If Applicable]								</li>
                <li>Media center (end ot end each way).								</li>
                <li>Bus ramp views (end to end - ramp to building view - building to ramp view)								</li>
                <li>Courtyard and/or patio views (end to end).  								</li>
                <li>Exterior Kitchen and Custodial Delivery area(s)								</li>
                <li>LP tank or Natural Gas meter area				[If Applicable}				</li>
                <li>Flammable material storage room area								</li>
                <li>Fuel depot area on campus       [If Applicable]								</li>
                <li>All driveway entrances to the campus showing that gates are or are not present.								</li>
                <li>Electrical Main Shut Off								</li>
                <li>Potable Water Main Shut Off</li>
                <li>MOU for each reunification site</li>
                <li> Any thing else on campus that staff believes could be critical information to show								</li>
                in case of an emergency on campus.	
            </ol>
          </div>							
                  
          <div className="flex-row">             
            Note - we ask that the deputy/guard assigned to each campus and the administration staff upload individual photos of themselves to the Photos and Documents Section.  Officers should also check the Points of Contact Section of the school's Asset page on FSSAT and include their name and cell phone number in that section.								
          </div>                   
                                
          <div className="flex-row">
            { assetNames.map( (fileName, index) => {
              return(
                <div className="flex-col uploaded-file-container" key={index}>
                  { fileName.isImage ?
                    <img  
                      src={`/api/emergencyManagement/getAsset?assetId=${fileName.emergencyManagementAssetId}`}
                      className="uploaded-file"
                      data-fileName={fileName.fileName}
                      alt={ fileName.notes || fileName.fileName  }
                      title={fileName.notes || fileName.fileName }
                      onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                    />
                  :
                    <span 
                      className="fa fa-file uploaded-file"
                      data-fileName={fileName.fileName}
                      alt={ fileName.notes || fileName.fileName  }
                      title={fileName.notes || fileName.fileName }
                      onClick={e => { setFileViewerAssetIndex(index); setFileViewerOpen(true) }}
                    />
                  }

                  <span>
                      <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteImage(fileName.emergencyManagementAssetId) }}></i>
                  </span>
                <div className="uploaded-file-caption">
                  {fileName.notes || fileName.fileName}
                </div>
                </div>
              )
              }) 
            }
          </div>

          <div className="dropzonearea flex-row">                  
            <DropzoneAreaBase
              filesLimit={30}
              maxFileSize={30000000}
              onAdd={(fileObjs) => { let newFiles = files.concat(fileObjs); setFiles(newFiles);  handleUploadFile(newFiles)}}
              fileObjects={files}
              />
          </div>
        </Paper>
    </div>
  </>
  )
}











