import React from 'react'
import AppContext from '../context/app'

import emergencyManagementService from '../services/emergencyManagement';
import { TextField, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';



class templateModuleRow extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { subModuleData: { data: [{}] } }
    this.emergencyManagementService = new emergencyManagementService();
    this.handleChange = this.handleChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.updateModuleProp = this.updateModuleProp.bind(this);
}

  updateModuleProp(changeObject){
    let activeEmergencyManagementPlanModule = this.context.activeEmergencyManagementPlanModule ? this.context.activeEmergencyManagementPlanModule.slice() : []
    let activeEmergencyManagementPlanSubModule = activeEmergencyManagementPlanModule.find( (subModule) => { return subModule.subModuleId === this.props.configItem.subModuleId } )
    if (activeEmergencyManagementPlanSubModule)        
        activeEmergencyManagementPlanSubModule.data = changeObject.data
    else
        activeEmergencyManagementPlanModule.push({ subModuleId: this.props.configItem.subModuleId, data: changeObject.data } )

        this.context.updateActiveEmergencyManagementPlanModule(activeEmergencyManagementPlanModule)
  }

  componentWillMount () {
    let configItem = this.props.configItem;
    
    //load up what data
    let loadData = [this.emergencyManagementService.getSubModuleData(this.props.configItem.institutionBuildingId, this.props.configItem.moduleName, this.props.configItem.subModuleId)]
    if (configItem.titleFile)
        loadData.push(this.emergencyManagementService.getSubModuleDefaultContent(configItem.titleFile, this.props.configItem.institutionGroupId))

    Promise.all(loadData)
    .then( (subModuleDataResult) => {
        let subModuleData = subModuleDataResult[0][0] || { data: '[{}]' }
        subModuleData.content = subModuleDataResult[1] || ''
        subModuleData.data = JSON.parse(subModuleData.data)

        if (configItem.minRows){
            while (subModuleData.data.length < configItem.minRows)
                subModuleData.data.push({})
        }

        this.setState({ subModuleData })

        this.updateModuleProp(subModuleData)
    })
    .catch( (error) => {
        console.log(error);
    })
    
  }

  handleChange(event, index) {
    let changeObject = this.state.subModuleData
    changeObject.data[index][event.target.name] = event.target.value;    
    this.setState({ subModuleData: changeObject });

    this.updateModuleProp(changeObject)
  }

  handleAdd() {
    let changeObject = this.state.subModuleData
    changeObject.data.push({})
    this.setState({ subModuleData: changeObject });
  }

  handleRemove(index) {
    let changeObject = this.state.subModuleData
    changeObject.data.splice(index, 1);
    this.setState({ subModuleData: changeObject });
  }

  render () {
    let colClass = 'width-44'
    if (this.props.configItem.fields.length === 3)
        colClass = 'width-30'
    if (this.props.configItem.fields.length === 4)
        colClass = 'width-22'


    return (
      <div className='templateModuleRow'>
        <div>
            <span dangerouslySetInnerHTML={ {__html: this.state.subModuleData.content } } ></span>
        </div>
        <div>
            <div className="flex-row header row">
                {
                    this.props.configItem.fields.map( (field, index) => {
                        return (
                            <div key={index} className={`flex-col header ${colClass}`}>{field}</div> 
                        )
                    })
                }
                <span className="flex-col row-action"></span>
            </div>
                
            { this.state.subModuleData.data.map( (row, index) => {
                return (
                    <div key={index} className="flex-row row">
                        {
                            this.props.configItem.fields.map( (field, fieldIndex) => {
                                return (
                                    <TextField type="text" key={fieldIndex} className={`flex-col input ${colClass}`} name={field} value={row[field]} defaultValue={this.state.subModuleData[index[field]]} onChange={(e) => this.handleChange(e, index)} />
                                )
                            })
                        }

                        
                        <span className="flex-col row-action">
                            <span className="flex-row">
                                { index === this.state.subModuleData.data.length-1 ?
                                    <span className="flex-col add">
                                        <Fab size="small" color="primary" aria-label="add" onClick={this.handleAdd}>
                                            <AddIcon /> 
                                        </Fab>
                                    </span>
                                : null }

                                { index > 0 && index+1 > (this.props.configItem.minRows || 1) ?
                                    <span className="flex-col">
                                        <Fab size="small" aria-label="add" onClick={(e) => this.handleRemove(index)}>
                                            <RemoveIcon /> 
                                        </Fab>
                                    </span>
                                : null }
                            </span>
                        </span>
                    </div>  
                )
            })}
        </div>
      </div>
    )
  }
}

export default templateModuleRow;
