import React, { useReducer, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Button, Icon, TextField, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import AddRemove from './AddRemove'

import AppContext from '../../../context/app'
import emergencyManagementService from '../../../services/emergencyManagement'

const SUBMODULE_ID = 3

export default function Component(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const { institutionBuildingId } = useParams()

  const [studentReunificationData, setStudentReunificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { buildings: [], hvacSystems: [{}] }
  );

  useEffect( async () => {
    window.scroll(0,0)
    try {
      let [subModuleData, subModulePropertyData] = await Promise.all([
        new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', SUBMODULE_ID),
        new emergencyManagementService().getSubModuleData(institutionBuildingId, 'studentReunification', 2)
      ])
      subModuleData = subModuleData[0]
      subModulePropertyData = subModulePropertyData[0]
      if (subModuleData) 
        subModuleData = JSON.parse(subModuleData.data)
      else 
        subModuleData = {}
      if (subModulePropertyData) {
        subModulePropertyData = JSON.parse(subModulePropertyData.data)
        subModuleData.buildings = subModulePropertyData.buildings
      }
      setStudentReunificationData(subModuleData)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const saveStudentReunfication = async (event) =>{
    event.preventDefault();
    try {
      let emergencyManagementPlanModule = { institutionBuildingId, moduleName: 'studentReunification', subModules: [{ subModuleId: SUBMODULE_ID,  data: studentReunificationData }] };
      await new emergencyManagementService().update(emergencyManagementPlanModule)
      context.toastSuccess('Saved')
      history.push(`/emergencyManagement/reunification/community/${institutionBuildingId}`)
    }
    catch(err){
      console.log(err)
      context.toastError('Saving')
      Sentry.captureException(err);
    }
  }

  if (studentReunificationData.buildings && !studentReunificationData.buildings.length)
    return ( <div className="studentReunification"><Paper className="paper"><div className="flex-row"><div className='flex-col'>Please enter your building data in the Property Tab</div></div></Paper></div> )

  return (
    <div className="studentReunification">
        <Paper className="paper">
            <form onSubmit={saveStudentReunfication} method='post' encType="multipart/form-data">
            <h2>Portable Water</h2>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Is there one location where the potable water supply for the entire campus can be shut off?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasPortableWaterShutOff === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasPortableWaterShutOff = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasPortableWaterShutOff === 'No'} label="No" onChange={(e) => { studentReunificationData.hasPortableWaterShutOff = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasPortableWaterShutOff === 'Yes' ?
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
                  Describe locations(s) of main valve(s) to shut off all potable water.  If there is more than one potable water service main supplying the campus, provide information on								
                  each one and main areas each service feeds if known.  Also advise if any special tool or								
                  device is needed to shut off the water.  (This is not to include water supply for the fire 								
                  suppression system / fire sprinklers if the campus is so equipped).  On the perimeter of 								
                  the campus, there may be a large pipe that comes up out of the ground with a couple of 								
                  valves on top and goes back in the ground.   Provide the location of this device on campus if 								
                  there is one.  Also add a labeled picture of the shutoff in the Photos and Documents Section.								
               </div>
               <div>
                 <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.portableWaterShutOffComment || ''} onChange={(e) => { studentReunificationData.portableWaterShutOffComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
               </div>
             </div>
            </div>
            : null }

            <h2>Electricity</h2>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Is there one centralized location where the electrical service for the entire campus can be shut off?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasElectricityShutOff === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasElectricityShutOff = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasElectricityShutOff === 'No'} label="No" onChange={(e) => { studentReunificationData.hasElectricityShutOff = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasElectricityShutOff === 'Yes' ?
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
                Describe location(s) of the electrical main shut off(s) for the campus.						
                If the campus has more than one electrical service feed, provide information on each one								
                and main areas each service feeds if known.  Add a labeled photo to the Photos and Documents								
                section of the asset page for your school of the main electrical shutoffs.  																
               </div>
               <div>
                 <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.electricityShutOffComment || ''} onChange={(e) => { studentReunificationData.electricityShutOffComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
               </div>
             </div>
            </div>
            : null }

            <h2>Natural Gas</h2>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Is there a natural gas service line on campus?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasNaturalGas === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasNaturalGas = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasNaturalGas === 'No'} label="No" onChange={(e) => { studentReunificationData.hasNaturalGas = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasNaturalGas === 'Yes' ?
            <div className="flex-row top-connected">
                <div className='flex-col full-width'>
                <TextField label="Gas company name and 24 hr contact number?" type="text" multiline={true} maxLength="300" value={studentReunificationData.naturalGasCompany || ''} required onChange={(e) => { studentReunificationData.naturalGasCompany = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>
            : null }

            { studentReunificationData.hasNaturalGas === 'Yes' ?
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
               Describe where the main shut off for the natural gas line can be found and what the natural gas service is used for on campus.																
               </div>
               <div>
                 <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.naturalGasComment || ''} onChange={(e) => { studentReunificationData.naturalGasComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
               </div>
             </div>
            </div>
            : null }


            <h2>Liquified Propane (LP Gas)</h2>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Is there a liquified propane (LP Gas) storage tank on the campus?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasPropane === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasPropane = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasPropane === 'No'} label="No" onChange={(e) => { studentReunificationData.hasPropane = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasPropane === 'Yes' ?
            <div className="flex-row top-connected">
                <div className='flex-col full-width'>
                <TextField label="Gas company name and 24 hr contact number?" type="text" multiline={true} maxLength="300" value={studentReunificationData.propaneGasCompany || ''} required onChange={(e) => { studentReunificationData.propaneGasCompany = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                </div>
            </div>
            : null }

            { studentReunificationData.hasPropane === 'Yes' ?
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
               Describe where the main shut off for the LP gas can be found and what the LP gas is used for on campus.
               </div>
               <div>
                 <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.propaneComment || ''} onChange={(e) => { studentReunificationData.propaneComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
               </div>
             </div>
            </div>
            : null }


            <h2>Back Up Power Generation</h2>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Does the campus have an emergency power backup generator?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasGenerator === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasGenerator = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasGenerator === 'No'} label="No" onChange={(e) => { studentReunificationData.hasGenerator = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasGenerator === 'Yes' ?
             <div className="flex-row">
              <div className='flex-col full-width'>
               <div className="textbox-label">
               Describe how the generator is powered (diesel fuel, natural gas, propane), where it
is located on campus and what parts of the campus does it supply.
               </div>
               <div>
                 <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.generatorComment || ''} onChange={(e) => { studentReunificationData.generatorComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
               </div>
             </div>
            </div>
            : null }

            <h2>Heating, Ventilation and Air Conditioning Controls (HVAC)</h2>
            <div className="flex-row">
              <div className='flex-col radio-col'>
                <FormControl component="fieldset">
                  <FormLabel>Does the campus have Heating, Ventilation and Air Conditioning Controls?</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel value="Yes" control={<Radio required />} checked={studentReunificationData.hasHVAC === 'Yes'} label="Yes" onChange={(e) => { studentReunificationData.hasHVAC = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                    <FormControlLabel value="No" control={<Radio required />} checked={studentReunificationData.hasHVAC === 'No'} label="No" onChange={(e) => { studentReunificationData.hasHVAC = e.target.value; setStudentReunificationData(studentReunificationData)}} />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            { studentReunificationData.hasHVAC === 'Yes' ?
            <div>
              <div className="flex-row">
                <div className='flex-col full-width'>
                  <div className="textbox-label">
                  Campus HVAC Systems can vary greatly from campus to campus.  Some campuses have a chiller plant that may run the entire facility.  Some campuses have HVAC systems designed to cover individual buildings.  Some campuses have separate HVAC systems (usually wall mounted) that are individual to every single classroom.  Describe which style of system your campus has and also where shut off controls can be found for the HVAC in the space below.  Do not list HCPS AC Department as the source of controls.  There is a way on campus to shut down the HVAC system(s) during an emergency.  Contact your head custodian if you need assistance.								
                  </div>
                  <div>
                    <TextField label="Description" type="text" multiline={true} maxLength="300" value={studentReunificationData.hvacComment || ''} onChange={(e) => { studentReunificationData.hvacComment = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
              </div>

              <div>
              { studentReunificationData.hvacSystems.map( (hvacSystem, index) => {
                  return (
                    <div key={index} className="flex-row row top-connected">
                        <div className='flex-col'>
                          <TextField select label="Building" type="text" maxLength="45" value={hvacSystem.building || ''} required onChange={(e) => { hvacSystem.building = e.target.value; setStudentReunificationData(studentReunificationData)}}>
                            { studentReunificationData.buildings.map( (building, index) => {
                              return (<MenuItem key={index} value={building.buildingName}>{building.buildingName}</MenuItem>)
                            })}
                          </TextField>                
                        </div>

                        <div className='flex-col'>
                          <TextField label="Room" type="text" maxLength="45" value={hvacSystem.room || ''} required onChange={(e) => { hvacSystem.room = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <TextField label="Location" type="text" maxLength="45" value={hvacSystem.location || ''} required onChange={(e) => { hvacSystem.location = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                        </div>
                        <div className='flex-col'>
                          <AddRemove OnUpdate={ (updatedArray) => { studentReunificationData.hvacSystems = updatedArray; setStudentReunificationData(studentReunificationData); } } addRemoveArrayIndex={index} addRemoveArray={studentReunificationData.hvacSystems}></AddRemove>
                        </div>
                    </div>
                  )
                })}
              </div>
            </div>
            : null }
            
           <h2>Addtional Comments</h2>
          <div className="flex-row">
                <div className='flex-col full-width'>
                  <div>
                    <TextField label="Additional Comments" type="text" multiline={true} maxLength="300" value={studentReunificationData.additionalComments || ''} onChange={(e) => { studentReunificationData.additionalComments = e.target.value; setStudentReunificationData(studentReunificationData)}}/>
                  </div>
                </div>
            </div>

            <div className="flex-row">
                <div className=''>
                <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
            </div>
            </form>
        </Paper>
    </div>
  )
}