import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { Button, Icon,  Checkbox, TextField, MenuItem } from '@mui/material';

import Dialog from '@mui/material//Dialog';
import DialogTitle from '@mui/material//DialogTitle';
import DotMenu from '../../components/DotMenu';
import DotMenuItem from '../../components/DotMenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import AppContext from '../../context/app'
import institutionService from '../../services/institution';
import emergencyManagementService from '../../services/emergencyManagement'

export default function Component(props) {
  const context = useContext(AppContext)
  const { institutionBuildingId } = useParams()

  const [pdfs, setPdfs] = useState([])
  const [building, setBuilding] = useState()
  const [modules, setModules] = useState([])
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
  const [downloadOptions, setDownloadOptions] = useState([])
  const [documentFormat, setDocumentFormat] = useState('pdf')


  useEffect( async () => {
    window.scroll(0,0)
    try {
      context.updateInstitutionBuildingId(institutionBuildingId)

      let [ institutionBuilding,modules,pdfs ] = await Promise.all([
        new institutionService().getBuilding(institutionBuildingId),
        new emergencyManagementService().getAllEmergencyManagementSubModules(institutionBuildingId),
        new emergencyManagementService().getEmergencyManagementPdf(institutionBuildingId || '')
      ])
      modules = [...new Set(modules.map(item => item.moduleName))];
      setBuilding(institutionBuilding)
      setModules(modules)
      setPdfs(pdfs)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
    }
  },[])

  const handleBegin = () => {
    context.updateActiveModule('Emergency Plan Overview')      
  }

  const handleArchive = async () => {
    try {
      await new emergencyManagementService().archive(institutionBuildingId)
      //update pdfs for dropdown
      let pdfs = await new emergencyManagementService().getEmergencyManagementPdf(institutionBuildingId || '')
      setPdfs(pdfs)
      context.toastSuccess('Archive saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Archiving plan')
    }
  }

  const downloadPdf = (e) => {
    e.preventDefault()
    new emergencyManagementService().downloadPdf(institutionBuildingId, pdfs[0].fileName)
  }

  const downloadOneOfPdf = (event) => {
    if (!event.target.value)
      return

    new emergencyManagementService().downloadPdf(institutionBuildingId, event.target.value)
  }

  const handleChangeDownloadOptions = (event) => {
    let updateDownloadOptions = [...downloadOptions]
    if (event.target.checked)
      updateDownloadOptions.push(event.target.name)
    else
      updateDownloadOptions = updateDownloadOptions.filter( o => o !== event.target.name)
    setDownloadOptions(updateDownloadOptions)
  };

  const download = (e, options) => {
    e.preventDefault()
    new emergencyManagementService().download(institutionBuildingId, downloadOptions, documentFormat)
  }

  return (
      <div id='emergencyManagementPlanDetail'>
         <div id="menu-col" >
          <DotMenu>
              <DotMenuItem onClick={(e) => { setOpenDownloadDialog(true) } } fontAwesomeIcon="fa fa-download" title="Download" ></DotMenuItem> 
          </DotMenu>
        </div>
        <Dialog open={openDownloadDialog} onClose={(e) => setOpenDownloadDialog(false)} aria-labelledby="simple-dialog-title">
            <DialogTitle id="dialog-title">Download Plan:</DialogTitle>
            <div className="share-dialog">
                <form onSubmit={download}>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={downloadOptions.includes('plan')} onChange={handleChangeDownloadOptions} name="plan" />
                      }
                      label="Emergency Plan"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={downloadOptions.includes('policies')} onChange={handleChangeDownloadOptions} name="policies" />
                      }
                      label="Policies"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={downloadOptions.includes('studentReunification')} onChange={handleChangeDownloadOptions} name="studentReunification" />
                      }
                      label="Student Reunification"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={downloadOptions.includes('siteVisit')} onChange={handleChangeDownloadOptions} name="siteVisit" />
                      }
                      label="Safety Compliance Visit"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={downloadOptions.includes('safetyEvent')} onChange={handleChangeDownloadOptions} name="safetyEvent" />
                      }
                      label="Safety Drills & Training"
                    />
                  </FormGroup>

                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                  <FormGroup>                          
                    <TextField select label="Document Format" type="text" value={documentFormat || ''} required onChange={(e) => { setDocumentFormat(e.target.value);}}>
                              <MenuItem value="pdf">PDF</MenuItem>
                              <MenuItem value="docx">Word</MenuItem>
                          </TextField>
                  </FormGroup>
                  </FormControl>


                </FormControl>
                    <div className="share-button-container">
                      <Button type="submit" disabled={!downloadOptions.length} className='share' variant="outlined"><Icon className="fa fa-download" />Download</Button>
                    </div>
                </form>
            </div>
        </Dialog>
        
        { building ?
          <div>
            { pdfs.length === 1 ? 
              <span className="pdf">
                <span onClick={downloadPdf}><i className="fa fa-file"></i> Archived pdf</span>
              </span>
            : null }

            { pdfs.length > 1 ? 
              <span className="pdfs">
                <select name="pdfs" onChange={downloadOneOfPdf} >
                <option value="">Archived pdf</option>
                { pdfs.map( (pdf, index) => {
                  return (
                    <option key={index} value={pdf.fileName}>{moment(pdf.timeStamp).format('MM/DD/YYYY')}</option>
                  )
                })}
                </select>

              </span>
            : null }

              <h2>Emergency Management Plan - <span dangerouslySetInnerHTML={ { __html: building.buildingName} }></span></h2>
              
              <p><strong>THE SCHOOL EMERGENCY MANAGEMENT PLAN</strong></p>
              
              <p>Following the tragic events that took place at Columbine High School, Sandy
Hook Elementary School, and other schools around the country, school officials
across America took a closer look at their safety and security needs. Although
safety continues to improve, there still remains several questions and concerns
from parents, students, school employees, and the community about how safe
their school is, especially following a publicized tragic event at a school.
Eventually, state legislators around the United States passed laws that
mandated schools to develop adequate emergency plans. Although emergency
plans differ from state to state, there are key elements recommended by the
Federal Emergency Management Agency (FEMA) that should be included as part
of every school safety plan. These elements include: Prevention-Mitigation,
Preparedness, Response, and Recovery.</p>

<p>The National Incident Management System (NIMS) is the United States’ single,
comprehensive system for managing domestic incidents and is suitable for all
schools and educational institutions to implement throughout all phases of
school emergency. The Incident Command System (ICS) is a component of
NIMS and is a standardized, on-scene, all-hazards incident management
approach that allows for the integration of facilities, equipment, personnel,
procedures, and communications operating within a common organizational
structure. ICS enables a coordinated response among various jurisdictions and
functional agencies, both public and private. NIMS and ICS are being adopting
by schools across the country.</p>

<p>The School Emergency Plan developed by CLPS is a comprehensive digitized
program that meets “best practice” standards for the all-hazards approach
necessary to protect schools. We are confident that by utilizing this support
software, you will be able to manage safety, security, and emergency
preparedness at your school more proficiently.</p>

            <div className="flex-row">
              <span className="action flex-col">
                <Link className='btn-next' to={`/emergencyManagement/templateModule/emergencyPlanOverviewModule/${+institutionBuildingId || ''}`} onClick={handleBegin}>Start</Link>
              </span>

              { modules && modules.length >= 5 ?
                <span className="action flex-col">
                  <span className='btn-next btn-left btn-archive' onClick={handleArchive}>
                    Save Plan
                  </span>
                </span>
              : null }
            </div>
          </div>
         : null }
      </div>
  )
}
