import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { getCookie } from 'react-use-cookie';

import userService from '../services/user';

import AppError from '../components/AppError'
import Sidenav from '../components/Sidenav'
import Message from '../components/Message'
import Header from '../components/Header'

import ga from 'react-ga';
import AppContext from '../context/app'

import General from './emergencyManagement/reunification/General'
import Property from './emergencyManagement/reunification/Property'
import Assignment from '././emergencyManagement/reunification/Assignment'
import Community from '././emergencyManagement/reunification/Community'
import Hazard from '././emergencyManagement/reunification/Hazard'
import Photo from '././emergencyManagement/reunification/Photo'
import Reunification from '././emergencyManagement/reunification/Reunification'
import Threat from '././emergencyManagement/reunification/Threat'
import Utility from '././emergencyManagement/reunification/Utility'

import SiteVisitExterior from './emergencyManagement/siteVisit/Exterior'
import SiteVisitInterior from './emergencyManagement/siteVisit/Interior'
import SiteVisitCLPS from './emergencyManagement/siteVisit/CLPS'
import SiteVisitOther from './emergencyManagement/siteVisit/Other'
import SiteVisitTabRouter from './emergencyManagement/siteVisit/TabRouter';


import EmergencyManagementList from './emergencyManagement/List'
import EmergencyManagementDetail from './emergencyManagement/Detail'
import TemplateModule from './emergencyManagement/TemplateModule'
import SafetyEventModule from './emergencyManagement/safetyEventModule'
import DocumentsModule from './emergencyManagement/documentsModule'
import CertificateModule from './emergencyManagement/certificateModule'
import Policy from  './emergencyManagement/Policy'
import PoliciesList from './emergencyManagement/PoliciesList'
import FileList from './file/List'
import TabRouter from './emergencyManagement/reunification/TabRouter';

import '../css/app.css'

export default function App(props) {
  const context = useContext(AppContext)
  const location = useLocation()

  const [loading, setLoading] = useState(true)
  const [showSideNav, setShowSideNav] = useState(true)
  
  let isNavPage = () => {
    return window.location.pathname !== '/' && window.location.pathname.indexOf('emergencyManagement/list') === -1
  }

  useEffect(() => {
    //act like you changed pages by going to top
    window.scroll(0, 0)

    setShowSideNav(isNavPage())

    //Log in Analytics
    ga.set({ page: location.pathname });
    ga.pageview(location.pathname);

    // //security
    if (!getCookie('clps'))
      window.location = 'https://login.clpsconsultants.com/'
    
  }, [location]);

  useEffect( async () => {
    //Default page
    if (!getCookie('clps'))
      window.location = 'https://login.clpsconsultants.com/'
    else {
      try {
        //load up data and goto default page
        let user = await new userService().get()
        if (!user) {
          window.location = 'https://login.clpsconsultants.com/logout'
          return
        }

        console.log('logged in as: ', user.userId)
        ga.set({ user: user.userId });

        context.updateUser(user)
        setShowSideNav(isNavPage())
        setLoading(false)  

      }
      catch (err) {
        console.log(err);
        if (!process.env.REACT_APP_NO_RESET_ON_ERROR)
          window.location = 'https://login.clpsconsultants.com/'
      }

    }
  },[])

  if (loading)
    return null

  return (
      <div>
        <Message context={context}></Message>
        <Header context={context} />    
        <section className="flex-row content">
          <AppError>
            <Switch>
              <div className="flex-col content">
                <section className="flex-col sidebar-container">
                  { context.institutionBuildingId ?
                    <Sidenav buildingId={context.institutionBuildingId} />
                  : null }
                </section>
                <div className="flex-row">
                  <section className={showSideNav ? 'flex-col main-container' : ' flex-col main-container no-sidebar'}>
                      <Switch>
                        <Route path="/home" component={EmergencyManagementList}/>
                        <Route path="/emergencyManagement/list" component={EmergencyManagementList}/>
                        <Route path="/emergencyManagement/detail/:institutionBuildingId" component={EmergencyManagementDetail}/>
                        <Route path="/emergencyManagement/templateModule/:modulePage/:institutionBuildingId" component={TemplateModule}/>
                        <Route path="/emergencyManagement/safetyEventModule/:institutionBuildingId" component={SafetyEventModule}/>
                        <Route path="/emergencyManagement/documentsModule/:type/:institutionBuildingId" component={DocumentsModule}/>
                        <Route path="/emergencyManagement/certificateModule/:institutionBuildingId" component={CertificateModule}/>
                        <Route path="/emergencyManagement/policies/list/:institutionBuildingId" component={PoliciesList}/>
                        <Route path="/emergencyManagement/policy/:institutionBuildingId/:policyId" component={Policy}/>

                        <Route path="/emergencyManagement/reunification/*/:institutionBuildingId">
                          <TabRouter/>
                          <Route path="/emergencyManagement/reunification//:institutionBuildingId" component={General}/>
                          <Route path="/emergencyManagement/reunification/general/:institutionBuildingId" component={General}/>
                          <Route path="/emergencyManagement/reunification/property/:institutionBuildingId" component={Property}/>
                          <Route path="/emergencyManagement/reunification/utility/:institutionBuildingId" component={Utility}/>
                          <Route path="/emergencyManagement/reunification/community/:institutionBuildingId" component={Community}/>
                          <Route path="/emergencyManagement/reunification/hazard/:institutionBuildingId" component={Hazard}/>
                          <Route path="/emergencyManagement/reunification/photo/:institutionBuildingId" component={Photo}/>
                          <Route path="/emergencyManagement/reunification/assignment/:institutionBuildingId" component={Assignment}/>
                          <Route path="/emergencyManagement/reunification/reunification/:institutionBuildingId" component={Reunification}/>
                          <Route path="/emergencyManagement/reunification/threat/:institutionBuildingId" component={Threat}/>
                        </Route>

                        <Route path="/emergencyManagement/sitevisit/*/:institutionBuildingId">
                          <SiteVisitTabRouter/>
                          <Route path="/emergencyManagement/sitevisit//:institutionBuildingId" component={SiteVisitExterior}/>
                          <Route path="/emergencyManagement/sitevisit/exterior/:institutionBuildingId" component={SiteVisitExterior}/>
                          <Route path="/emergencyManagement/sitevisit/interior/:institutionBuildingId" component={SiteVisitInterior}/>
                          <Route path="/emergencyManagement/sitevisit/clps/:institutionBuildingId" component={SiteVisitCLPS}/>
                          <Route path="/emergencyManagement/sitevisit/other/:institutionBuildingId" component={SiteVisitOther}/>
                        </Route>
                        <Route path="/file/:folder?" component={FileList}/>

                        <Redirect path="/" to="/emergencyManagement/list" />
                      </Switch>
                  </section>
                </div>
              </div>
            </Switch>
          </AppError>
      </section>
    </div>
  )
}