import React from 'react'
import AppContext from './app'

class AppProvider extends React.Component {
    constructor(props) {
        super(props);
        
        this.updateUser =  (user) => {
            this.setState( { user })
        }

        this.updateInstitutionBuildingId =  (institutionBuildingId) => {
            this.setState( { institutionBuildingId })
        }

        this.updateActiveModule =  (activeModule) => {
            this.setState( { activeModule })
        }

        this.updateActiveEmergencyManagementPlanModule = (activeEmergencyManagementPlanModule) => {
            this.setState( {activeEmergencyManagementPlanModule} )
        }

        this.toastSuccess = (message) =>{
            let toast = { type: 'success', message }
            this.setState( { toast })
            setTimeout( () => {
                this.setState( { toast: null })
            },2000 )
        }

        this.toastError = (message) =>{
            let toast = { type: 'error', message }
            this.setState( { toast })
            setTimeout( () => {
                this.setState( { toast: null })
            },2000 )  
        }

        this.removeToast = ()=>{
            this.setState( { toast: null })
        }
        
        
        this.state = {
            updateUser: this.updateUser,
            updateInstitutionBuildingId: this.updateInstitutionBuildingId,
            updateActiveModule: this.updateActiveModule,
            updateActiveEmergencyManagementPlanModule: this.updateActiveEmergencyManagementPlanModule,
            toastSuccess: this.toastSuccess,
            toastError: this.toastError,
            removeToast: this.removeToast
        }
    }

    render () {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}
export default AppProvider