import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppContext from '../context/app'

import institutionService from '../services/institution';
import emergencyManagementService from '../services/emergencyManagement'
import safetyEventService from '../services/safetyEvent';
import moment from 'moment';


const defaultTabs = [
  { name: 'Dashboard', location: '/emergencyManagement/list', manyBuildings: true, showName: true },
  { name: 'Emergency Plan', location: '/emergencyManagement/detail', default: true },
  { name: 'Emergency Plan Overview', location: '/emergencyManagement/templateModule/emergencyPlanOverviewModule' },
  { name: 'Functional Annexes', moduleName: 'functionalAnnexesModule',  location: '/emergencyManagement/templateModule/functionalAnnexesModule' },
  { name: 'Threat Specific Annexes', moduleName: 'threatSpecificAnnexesModule', location: '/emergencyManagement/templateModule/threatSpecificAnnexesModule' },
  { name: 'School Safety Teams', moduleName: 'schoolSafetyTeamsModule', location: '/emergencyManagement/templateModule/schoolSafetyTeamsModule' },
  { name: 'Organizational Incident Command System', moduleName: 'organizationalIncidentCommandSystemModule', location: '/emergencyManagement/templateModule/organizationalIncidentCommandSystemModule' },
  { name: 'Reunification', location: '/emergencyManagement/reunification/', moduleName: 'studentReunification' },
  { name: 'Policies', location: '/emergencyManagement/policies/list', additionalActiveLocations: ['/emergencyManagement/policy'], completeOnAcknowledgedAllPolicies: true },
  { name: 'Safety Drills and Training', location: '/emergencyManagement/safetyEventModule', checkHasRecentSafetyEvent: true },
  { name: 'Images / Floor Plans / Documents', location: '/emergencyManagement/documentsModule/document' },
  { name: 'Certificates', location: '/emergencyManagement/certificateModule' },
  { name: 'Safety Compliance Visit', location: '/emergencyManagement/sitevisit/', moduleName: 'siteVisit' }
]

export default function SideNav(props) {
  const context = useContext(AppContext)
  const location = useLocation()

  const [building, setBuilding] = useState({})
  const [activeTab, setActiveTab] = useState(null)
  const [showNav, setShowNav] = useState(null)
  const [tabs, setTabs] = useState(JSON.parse(JSON.stringify(defaultTabs)))
  
  const setTheActiveTab = () => {
    let updatedTabs = [...tabs]
    let tab = updatedTabs.find( t => location && (location.pathname.indexOf(t.location) > -1 || (t.additionalActiveLocations && t.additionalActiveLocations.some( aal => location.pathname.indexOf(aal) > -1 ))))
    if (tab)
      setActiveTab(tab)
    else 
      setActiveTab(updatedTabs.find( t => t.default ))
  }

  useEffect( async () => {
    window.scroll(0,0)
    setTheActiveTab()
  },[])

  useEffect( () => {
    setTheActiveTab()
  },[location])

  useEffect( async () => {
    if (props.buildingId){ 
      let updatedTabs = JSON.parse(JSON.stringify(defaultTabs))
      //if you only have one building you do not need to link to the dashboard
      let buildings = await new institutionService().getAllBuildings()
      if (buildings.length === 1)
        updatedTabs = tabs.filter( t => !t.manyBuildings)
      setBuilding(buildings.find( b => b.institutionBuildingId === +props.buildingId))

      let [savedModules, policies, safetyEvents] = await Promise.all([
        new emergencyManagementService().getAllEmergencyManagementSubModules(props.buildingId),
        new emergencyManagementService().getSubPolicies(props.buildingId),
        new safetyEventService().getSafetyEvents(props.buildingId)
      ])
  
      //count policies to know if its done
      let acknowledgedAllPolicies = !policies.find( p => !!p.subPolicies.find( sp => !sp.isAcknowledged) )
      //has recent safety event
      let hasRecentSafetyEvent = !!safetyEvents.find( se => moment(se.date) >= moment().subtract(2, 'month') )
      
      //loop through tabs to mark complete
      updatedTabs.forEach( (t) => {        
        if (savedModules.find( (savedModule) => { return savedModule.moduleName === t.moduleName  }))
          t.isComplete = true;
          
        if (t.completeOnAcknowledgedAllPolicies && acknowledgedAllPolicies)
          t.isComplete = true;
        
        if (t.checkHasRecentSafetyEvent && hasRecentSafetyEvent)
          t.isComplete = true;
      })
      setTabs(updatedTabs)
    }
  },[props.buildingId])

  return (
    <section id="sidebar">
      <section className="desktop">
      { 
        context.user && props.buildingId ?
            <div>
              { tabs.map( (tab, index) => {
                return(
                  <div key={index} className="emergencymanagment-link">
                    <Link className={`module flex-row ${activeTab && activeTab.name === tab.name ? 'active' : ''}`} to={`${tab.location}/${props.buildingId}`}>
                      <div className="linkStyle">{tab.name}</div>
                      { tab.showName ?
                        <div className="selectedBuildingName">{building.buildingName} Plan</div>
                      : null }
                      { tab.isComplete ?
                        <span>                                
                          <i className='complete-check fa fa-check-circle'></i>
                        </span>
                      :
                        null
                      }
                    </Link>
                  </div>
                )
              })}
            </div>
          : null
          }
      </section>

      <section className="tablet">
        <div>
          { !showNav ?
            <i className="fa fa-bars showNav-bar" onClick={(e) => setShowNav(!showNav)}></i>
          :
            <i className="fa fa-minus showNav-bar" onClick={(e) => setShowNav(!showNav)}></i>
          }
        </div>
      
        { showNav ?
          <div className="mobile-nav">
            { context.user && props.buildingId ?
              <div>
                { tabs.map( (tab, index) => {
                  return(
                    <div key={index} className="threatAssessment-link" onClick={(e) => setShowNav(false)}>
                      <Link className={`module flex-row ${activeTab && activeTab.name === tab.name ? 'active' : ''}`} to={`${tab.location}/${props.buildingId}`}>
                        <div className="linkStyle">{tab.name}</div>
                        { tab.isComplete ?
                        <span>                                
                          <i className='complete-check fa fa-check-circle'></i>
                        </span>
                      :
                        null
                      }
                      </Link>
                    </div>
                  )
                })}
              </div>
            : null
          }  
          </div>
        : null }
      </section>
    </section>
  )
}